import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './customBaseQuery';

export interface IVariantResponse {
	meta: IPagination;
	data: IVariant[];
}

export interface IPagination {
	page: {
		currentPage: number;
		from: number;
		lastPage: number;
		perPage: number;
		to: number;
		total: number;
	};
}

export interface IVariant {
	type: string;
	id: string;
	attributes: {
		product_id: string;
		price: number;
		name: string;
	};
}

export interface CreateCheckoutUrlRequest {
	enabled_variants: string[];
	expires_at: string;
}

export const paymentApi = createApi({
	reducerPath: 'paymentApi',
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getAllVariants: builder.query<IVariantResponse, void>({
			query: () => ({ url: 'api/payment/get-all-variants' })
		}),
		createCheckoutUrl: builder.mutation<{ data: { attributes: { url: string } } }, CreateCheckoutUrlRequest>({
			query: (body) => ({ url: `api/payment/create-checkout-url`, method: 'POST', body })
		}),
		cancelSubscription: builder.mutation<{ status: string; message: string }, void>({
			query: () => ({ url: `api/payment/cancel-subscription`, method: 'DELETE' })
		}),
		resumeSubscription: builder.mutation<{ status: string; message: string }, void>({
			query: () => ({ url: `api/payment/resume-subscription`, method: 'PATCH' })
		})
	})
});

export const {
	useLazyGetAllVariantsQuery,
	useCreateCheckoutUrlMutation,
	useCancelSubscriptionMutation,
	useResumeSubscriptionMutation
} = paymentApi;
