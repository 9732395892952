import React, { useEffect, useRef, useState } from 'react';
import { ITable } from '../../../types/myTables.types';
import {
	IconDots,
	IconInfoCircle,
	IconLock,
	IconPlus,
	IconSearch,
	IconSwitchHorizontal,
	IconSwitchVertical,
	IconTrash
} from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../states/store';
import {
	useDeleteTableMutation,
	useLazyGetTablesQuery,
	useUpdateActiveDaysMutation
} from '../../../services/api/myTables';
import {
	deleteTableSuccess,
	getTablesSuccess,
	setLoading,
	updateActiveDaysSuccess
} from '../../../states/table/myTablesSlice';
import { isCustomErrorResponse } from '../../../utils/errorUtils';
import toast from 'react-hot-toast';
import TableComponent from '../../../components/myTables/table/Table';
import { useLazyGetMeQuery } from '../../../services/api/auth';
import { IProfile, SubscriptionType, useLazyUpdateStreakDayQuery } from '../../../services/api/profile';
import {
	achievementEarnedSuccess,
	deleteTableFromProfileSuccess,
	getMyProfileSuccess,
	updateStreakDaySuccess
} from '../../../states/profile/profileSlice';
import { Tooltip } from 'react-tooltip';
import Button from '../../../components/button/button';

import noTable from '/src/assets/emptyStates/noTable.webp';
import CustomModule from '../../../components/customModule/customModule';
import AchievementToast from '../../../components/profile/achievements/AchievementToast';
import { IAchievement } from '../../../types/achievement.types';
import Subscription from '../Subscription/Subscription';
import { Helmet } from 'react-helmet';
import { useDebounce } from '../../../hooks/useDebounce';
import TableTemplates from './TableTemplates';

export default function MyTables() {
	const dispatch = useDispatch();
	const { myTables, pagination, isLoading, searchQuery } = useSelector((state: RootState) => state.myTables);
	const { profile } = useSelector((state: RootState) => state.profile);
	const [getTablesRequest] = useLazyGetTablesQuery();
	const [deleteTableRequest, { isLoading: deleteTableIsLoading }] = useDeleteTableMutation();
	const [updateStreakDayRequest] = useLazyUpdateStreakDayQuery();
	const [getMyProfile, { isLoading: getMyProfileIsLoading }] = useLazyGetMeQuery<IProfile>();
	const [updateActiveDaysRequest, { isLoading: updateActiveDayIsLoading }] = useUpdateActiveDaysMutation();
	const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
	const [isHorizontal, setIsHorizontal] = useState(localStorage.getItem('isHorizontal') === 'true');
	const observer = useRef<IntersectionObserver | null>(null);
	const [currentPage, setCurrentPage] = useState(pagination.page);
	const [selectedTableId, setSelectedTableId] = useState('');
	const [query, setQuery] = useState('');
	const [previousQuery, setPreviousQuery] = useState('');
	const debouncedQuery = useDebounce(query, 500);
	const [isQueryLoading, setIsQueryLoading] = useState(false);
	const [isLoadingMore, setIsLoadingMore] = useState(false);
	const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
	const [hasRedirectedToCheckout, setHasRedirectedToCheckout] = useState(false);

	const lastTableRef = (node: HTMLDivElement | null) => {
		if (isLoading || isLoadingMore) return;
		if (query) return;
		if (pagination.totalPages <= currentPage) return;
		if (myTables && myTables.length >= pagination.totalTables) return;
		if (observer.current) observer.current.disconnect();
		observer.current = new IntersectionObserver((entries) => {
			if (entries[0].isIntersecting && !isLoading && !isLoadingMore) {
				setIsLoadingMore(true);
				setCurrentPage((prevPage) => prevPage + 1);
			}
		});
		if (node) observer.current.observe(node);
	};

	useEffect(() => {
		const fetchTables = () => {
			if (!isLoadingMore) {
				dispatch(setLoading(true));
			}
			setPreviousQuery(debouncedQuery);
			setTimeout(async () => {
				try {
					const response = await getTablesRequest({
						page: currentPage,
						limit: debouncedQuery ? undefined : 10,
						query: debouncedQuery
					}).unwrap();
					if (response) {
						dispatch(
							getTablesSuccess({
								tables: response.tables,
								pagination: {
									page: response.pagination.page,
									totalPages: response.pagination.totalPages,
									totalTables: response.pagination.totalTables
								},
								query: debouncedQuery
							})
						);
					}
				} catch (error) {
					if (isCustomErrorResponse(error)) {
						toast.error(error.data.message);
					} else if (error instanceof Error) {
						toast.error(error.message);
					} else {
						toast.error('An unknown error occurred');
					}
				} finally {
					dispatch(setLoading(false));
					setIsQueryLoading(false);
					setIsLoadingMore(false);
				}
			}, 300);
		};

		if (debouncedQuery !== undefined && debouncedQuery !== previousQuery) {
			setIsQueryLoading(true);
			fetchTables();
		} else if (searchQuery !== undefined && searchQuery !== '' && debouncedQuery === '' && previousQuery === '') {
			setIsQueryLoading(true);
			fetchTables();
		} else if (!query) {
			if (myTables.length > 0 && Math.ceil(myTables.length / 10) === currentPage) return;
			fetchTables();
		}
	}, [currentPage, dispatch, debouncedQuery]);

	const isSameDay = (date1: Date, date2: Date) => {
		return (
			date1.getFullYear() === date2.getFullYear() &&
			date1.getMonth() === date2.getMonth() &&
			date1.getDate() === date2.getDate()
		);
	};

	useEffect(() => {
		const updateStreakDay = async () => {
			try {
				const response = await updateStreakDayRequest().unwrap();

				if (response) {
					if (response.alreadyUpdated) return;
					dispatch(updateStreakDaySuccess(response.streakDay));
				}
			} catch (error) {
				if (isCustomErrorResponse(error)) {
					toast.error(error.data.message);
				} else if (error instanceof Error) {
					toast.error(error.message);
				} else {
					toast.error('An unknown error occurred. Please refresh the page.');
				}
			}
		};
		if (isSameDay(new Date(profile.streakDay.lastStreakDate), new Date()) && !profile.streakDay.lastStreakDate) return;
		updateStreakDay();
	}, []);

	useEffect(() => {
		const checkProfile = async () => {
			if (profile && profile._id) {
				dispatch(getMyProfileSuccess(profile));
			} else {
				try {
					const response = await getMyProfile().unwrap();
					if (response) {
						dispatch(getMyProfileSuccess(response));
					}
				} catch (error) {
					if (isCustomErrorResponse(error)) {
						toast.error(error.data.message);
					} else if (error instanceof Error) {
						toast.error(error.message);
					} else {
						toast.error('An unknown error occurred');
					}
				}
			}
		};
		if (!isAuthenticated) return;
		checkProfile();
	}, [profile, isAuthenticated, dispatch]);

	const deleteTable = async () => {
		try {
			const response = await deleteTableRequest(selectedTableId).unwrap();
			if (response) {
				dispatch(deleteTableSuccess(selectedTableId));
				dispatch(deleteTableFromProfileSuccess(selectedTableId));
				setTimeout(() => {
					toast.success(response.message);
				}, 500);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred');
			}
		} finally {
			(document.getElementById('myTables-ays-modal') as HTMLDialogElement).close();
		}
	};

	const setTodaysColor = async (table: ITable, dataRangeId: string, year: string) => {
		try {
			setSelectedTableId(table._id as string);
			if (!table._id) return;
			if (!dataRangeId) return;
			const response = await updateActiveDaysRequest(dataRangeId).unwrap();
			if (response) {
				const matchingDataRange = response.data.dataRanges.find((dataRange) => dataRange._id === dataRangeId);
				if (matchingDataRange?.activeDays) {
					dispatch(
						updateActiveDaysSuccess({
							_id: table._id,
							activeDay: matchingDataRange.activeDays[year][matchingDataRange.activeDays[year].length - 1],
							dataRangeId: dataRangeId,
							year: year
						})
					);
				}
				if (response.achievements.length > 0) {
					dispatch(achievementEarnedSuccess(response.achievements));
					response.achievements.forEach((achievement: IAchievement) => {
						toast(
							<AchievementToast
								title={achievement.title}
								description={achievement.description}
								threshold={achievement.threshold}
							/>
						);
					});
				}
				toast.success(response.message);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		}
	};

	const clearInput = () => {
		setQuery('');
		handleChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setQuery(value);
		setCurrentPage(1);
	};

	const openAYSModule = (id: string) => {
		(document.getElementById('myTables-ays-modal') as HTMLDialogElement).showModal();
		setSelectedTableId(id);
	};

	return (
		<div className="w-full flex flex-1 flex-col">
			<Helmet>
				<title>Life on Track - My Tables</title>
				<meta
					name="description"
					content="Here you can view all your tables. You can navigate to your table details or you can quickly set the daily colors for all your tables from the colors next to your tables."
				/>
			</Helmet>
			{isSubscriptionModalOpen && (
				<Subscription
					id="subscription-modal"
					isSubscriptionModalOpen={isSubscriptionModalOpen}
					setIsSubscriptionModalOpen={setIsSubscriptionModalOpen}
					setHasRedirectedToCheckout={setHasRedirectedToCheckout}
				/>
			)}
			<CustomModule
				id="myTables-ays-modal"
				title="Are you sure you want to delete this table?"
				description="Deleting this table will permanently delete all the data associated with it. This action cannot be undone."
				buttonText="Delete Table"
				onButtonClick={() => {
					deleteTable();
				}}
				isLoading={deleteTableIsLoading}
			/>
			<TableTemplates />
			{hasRedirectedToCheckout && (
				<div className="fixed inset-0 z-50 bg-black bg-opacity-50">
					<div className="bg-lt-lightCream dark:bg-lt-dark-secondary-bg p-4 flex flex-col items-center gap-2 absolute top-0 left-0 right-0">
						<p className="text-lt-dark-primary-text dark:text-lt-dark-primary-text text-center">
							Please refresh the page to ensure the latest updates are displayed.
						</p>
						<Button
							variant="btn-primary-light"
							onClick={() => window.location.reload()}
							className="text-sm px-4 py-1"
							type={'button'}
						>
							Refresh Page
						</Button>
					</div>
				</div>
			)}

			<div className="w-full bg-lt-cream dark:bg-lt-dark-secondary-bg p-4 dark:border-lt-dark-secondary-border z-10">
				<div className="flex justify-between items-center sm:items-start gap-4 sm:px-2">
					<div>
						<h1 className="text-xl sm:text-4xl font-bold">My Tables</h1>
						<div className="hidden lg:flex items-center gap-2">
							<IconInfoCircle className="w-6 h-6 text-lt-dark-primary-text dark:text-lt-dark-primary-text text-lt-dark-inputPlaceholder" />
							<h6 className="text-sm text-lt-dark-inputPlaceholder max-w-[400px]">
								Click on the colors next to the table to record your daily results.
							</h6>
						</div>
					</div>
					<div className="flex justify-end lg:justify-around items-center gap-4 flex-1 lg:flex-none">
						<div className="relative flex-col w-full md:w-4/12 lg:w-6/12">
							<div className="flex items-center px-2 h-11 bg-lt-lightCream dark:bg-lt-dark-popupBorder border border-lt-beige dark:border-lt-dark-primary-border rounded-default relative">
								<IconSearch />
								<input
									disabled={isQueryLoading}
									value={query}
									onChange={handleChange}
									className="input input-bordered bg-transparent border-none outline-none focus:outline-none w-full pl-2"
									placeholder="Search Table"
									type="text"
								/>
								{query && (
									<IconTrash
										onClick={clearInput}
										className={`text-red-500 cursor-pointer absolute right-2 ${isQueryLoading ? 'cursor-not-allowed pointer-events-none opacity-50' : ''}`}
									/>
								)}
							</div>
						</div>
						<Button
							variant="btn-primary-light"
							className="hidden md:block"
							onClick={() => {
								clearInput();
								if (
									profile?.subscription.subscriptionType === SubscriptionType.FREE &&
									profile.subscription.isTrial === false
								) {
									setIsSubscriptionModalOpen(true);
									return;
								}
								(document.getElementById('create_table_modal') as HTMLDialogElement)?.showModal();
							}}
							type="button"
						>
							Create Table
						</Button>
						<Button
							variant="btn-primary-light"
							className="md:hidden"
							onClick={() => {
								clearInput();
								if (
									profile?.subscription.subscriptionType === SubscriptionType.FREE &&
									profile.subscription.isTrial === false
								) {
									setIsSubscriptionModalOpen(true);
									return;
								}
								(document.getElementById('create_table_modal') as HTMLDialogElement)?.showModal();
							}}
							type="button"
						>
							<IconPlus />
						</Button>
						<div>
							<div className="flex items-center justify-center">
								{isHorizontal ? (
									<>
										<button
											data-tooltip-id="switch-to-vertical-tooltip"
											data-tooltip-content="Switch to vertical view"
											data-tooltip-place="top"
											onClick={() => {
												localStorage.setItem('isHorizontal', 'false');
												setIsHorizontal(false);
											}}
										>
											<IconSwitchHorizontal />
										</button>
										<Tooltip className="hidden sm:block z-50" id="switch-to-vertical-tooltip" delayHide={100} />
									</>
								) : (
									<>
										<button
											data-tooltip-id="switch-to-horizontal-tooltip"
											data-tooltip-content="Switch to horizontal view"
											data-tooltip-place="top"
											onClick={() => {
												localStorage.setItem('isHorizontal', 'true');
												setIsHorizontal(true);
											}}
										>
											<IconSwitchVertical />
										</button>
										<Tooltip className="hidden sm:block z-50" id="switch-to-horizontal-tooltip" delayHide={100} />
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				{myTables && myTables.length && myTables.length > 0 && !profile.isLoading ? (
					<div className="h-[calc(100dvh-197px)] md:h-[calc(100vh-112px)] overflow-y-auto py-5">
						{isQueryLoading ? (
							<div className="col-span-3 flex justify-center items-center h-full">
								<span className="loading loading-spinner loading-lg text-center"></span>
							</div>
						) : (
							<div
								className={` grid w-ful place-items-center ${isHorizontal ? 'grid-cols-1 xl:grid-cols-2 gap-4 xl:gap-4 px-0 sm:px-4' : 'grid-cols-1 base:grid-cols-2 2xl:grid-cols-3 gap-4'}`}
							>
								{myTables.map((table: ITable, index) => (
									<div
										key={index}
										ref={index === myTables.length - 1 ? lastTableRef : null}
										className={`relative p-2 rounded-md bg-lt-lightCream dark:bg-lt-dark-popupBorder border border-lt-beige dark:border-lt-dark-primary-border ${isHorizontal ? 'w-full h-[300px] sm:h-[380px] md:h-[450px] xl:h-[400px] lg:px-2 lg:py-4 ' : 'w-10/12 2xl:w-10/12 min-w-[336px] h-[650px] lg:p-4'}`}
									>
										{updateActiveDayIsLoading && table._id === selectedTableId ? (
											<div className="h-full flex justify-center items-center">
												<span className="loading loading-spinner loading-lg"></span>
											</div>
										) : (
											<>
												<div className="flex justify-between items-center mb-2">
													<div className="flex gap-2 items-center">
														<Link
															to={`/dashboard/my-tables/${table._id}`}
															state={{ isHorizontal: isHorizontal }}
															className={`text-center flex items-center font-bold pl-2 hover:text-lt-beige ${table.title.length > 20 ? 'text-base sm:text-lg' : 'text-2xl'}`}
														>
															<i className={`${table?.icon} text-xl mr-2`}></i>
															{table.title}
														</Link>
														<span className="text-lt-beige dark:text-lt-dark-popupText">
															{table.isTablePublic ? 'Public' : 'Private'}
														</span>
													</div>
													<div className="dropdown dropdown-end">
														<div
															tabIndex={0}
															role="button"
															className="btn btn-circle btn-ghost btn-xs text-lt-beige dark:text-lt-dark-popupText"
														>
															<IconDots />
														</div>
														<div
															tabIndex={0}
															className="card compact dropdown-content z-[1] shadow bg-lt-light-secondary-bg dark:bg-lt-dark-secondary-bg text-lt-light-secondary-text dark:text-lt-text-dark rounded-box w-40"
														>
															<div tabIndex={0} className="card-body">
																<Link
																	to={`/dashboard/my-tables/${table._id}`}
																	state={{ isHorizontal: isHorizontal }}
																	className="btn btn-ghost hover:bg-lt-light-secondary-hover-bg dark:hover:bg-lt-dark-secondary-hover-bg"
																>
																	Table Details
																</Link>
																{deleteTableIsLoading ? (
																	<span className="loading loading-spinner loading-sm"></span>
																) : (
																	<button
																		disabled={deleteTableIsLoading}
																		onClick={() => openAYSModule(table._id as string)}
																		className="btn btn-ghost hover:bg-lt-light-secondary-hover-bg dark:hover:bg-lt-dark-secondary-hover-bg"
																	>
																		Delete Table
																	</button>
																)}
															</div>
														</div>
													</div>
												</div>

												<div
													className={`flex ${isHorizontal ? 'flex-col h-[225px] sm:h-[310px] md:h-[360px] xl:h-[320px] justify-between sm:justify-center sm:gap-2 md:gap-0 md:justify-between' : ''}`}
												>
													<TableComponent isHorizontal={isHorizontal} newTableData={table} componentUsed="myTables" />
													<div
														className={`grid ${isHorizontal ? 'w-full grid-rows-1 grid-flow-col' : 'w-4/12 grid-cols-1'}`}
													>
														{table.dataRanges.map((dataRange, index) => (
															<div
																key={index}
																className={`gap-2 flex items-center sm:justify-start ${isHorizontal ? 'flex-col justify-start' : 'flex-col sm:flex-row sm:ml-1'}`}
															>
																<button
																	disabled={updateActiveDayIsLoading}
																	onClick={() => {
																		if (dataRange._id)
																			setTodaysColor(table, dataRange._id, new Date().getFullYear().toString());
																	}}
																	style={{ backgroundColor: dataRange.hexColorCode }}
																	className="min-w-6 min-h-6 rounded-sm"
																></button>
																<div
																	data-tooltip-id="my-tables-dataRange-tooltip"
																	data-tooltip-content={dataRange.range + ' ' + (dataRange?.unit ?? '')}
																	data-tooltip-place="top"
																	className={`w-full flex  base:text-xs gap-0.5 ${isHorizontal ? 'text-center text-3xs sm:text-xs justify-center' : 'text-xs text-center sm:text-start justify-center sm:justify-start'}  ${dataRange.range.length > 10 ? 'flex-col' : ''} ${dataRange.unit?.length && dataRange.unit?.length > 10 ? 'flex-col' : ''}`}
																>
																	<h6>
																		{dataRange.range.length > 15
																			? `${dataRange.range.slice(0, 15)}...`
																			: dataRange.range}
																	</h6>
																	<h6>
																		{dataRange.unit?.length && dataRange.unit?.length > 15
																			? `${dataRange.unit.slice(0, 15)}...`
																			: dataRange.unit}
																	</h6>
																	<Tooltip className="z-50" id="my-tables-dataRange-tooltip" />
																</div>
															</div>
														))}
													</div>
												</div>
											</>
										)}
										{profile.subscription.subscriptionType === SubscriptionType.FREE &&
											profile.subscription.isTrial === false &&
											!profile.isLoading && (
												<button
													onClick={() => {
														setIsSubscriptionModalOpen(true);
													}}
													className="absolute inset-0 bg-gray-700 bg-opacity-75 p-4 flex flex-col rounded-md justify-center items-center text-white font-bold text-center border border-lt-beige dark:border-lt-dark-primary-border"
												>
													<IconLock className="mb-2" />
													<h6 className="text-sm">You need to upgrade your plan to create tables.</h6>
												</button>
											)}
									</div>
								))}
								{isLoadingMore && (
									<div className="col-span-full flex justify-center items-center py-4">
										<span className="loading loading-spinner loading-lg"></span>
									</div>
								)}
							</div>
						)}
					</div>
				) : (
					<>
						{!isLoading && (
							<div className="flex flex-col items-center justify-center h-screen px-4 sm:px-0 pb-40 sm:pb-0">
								<img className="w-60" src={noTable} alt="No table" />
								<span className="mt-4">No tables found. Click on the button above to create a new table.</span>
							</div>
						)}
					</>
				)}
			</div>
			{(isLoading || getMyProfileIsLoading) && (
				<div className="w-full flex justify-center items-center flex-grow h-screen">
					<span className="loading loading-spinner loading-lg"></span>
				</div>
			)}
		</div>
	);
}
