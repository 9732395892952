import { IconFileAnalytics, IconLock, IconPigMoney, IconTableHeart } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useCreateCheckoutUrlMutation, useLazyGetAllVariantsQuery } from '../../../services/api/payment';
import { getVariantsSuccess } from '../../../states/payment/paymentSlice';
import { isCustomErrorResponse } from '../../../utils/errorUtils';
import toast from 'react-hot-toast';
import { RootState } from '../../../states/store';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/button/button';
import { SubscriptionType } from '../../../services/api/profile';
import lemonSqueezyLogoLight from '../../../assets/lemon-squeezy-logo-light.svg';
import lemonSqueezyLogoDark from '../../../assets/lemon-squeezy-logo-dark.svg';
import { useTheme } from '../../../context/ThemeContext';

export default function Subscription({
	id,
	isChangeSubscription,
	isSubscriptionModalOpen,
	setIsSubscriptionModalOpen,
	setIsChangeSubscription,
	setHasRedirectedToCheckout
}: {
	id: string;
	isChangeSubscription?: boolean;
	isSubscriptionModalOpen?: boolean;
	setIsSubscriptionModalOpen?: (value: boolean) => void;
	setIsChangeSubscription?: (value: boolean) => void;
	setHasRedirectedToCheckout?: (value: boolean) => void;
}) {
	const dispatch = useDispatch();
	const [getAllVariants] = useLazyGetAllVariantsQuery();
	const { theme } = useTheme();
	const [createCheckoutUrl] = useCreateCheckoutUrlMutation();
	const [isCheckoutUrlLoading, setIsCheckoutUrlLoading] = useState(false);
	const [isPlansLoading, setIsPlansLoading] = useState(false);
	const { variants } = useSelector((state: RootState) => state.payment);
	const { profile } = useSelector((state: RootState) => state.profile);
	const [selectedPlan, setSelectedPlan] = useState<{
		enabled_variants: string[];
		expires_at: string;
	}>({
		enabled_variants: [],
		expires_at: ''
	});

	const getAvailablePlans = () => {
		if (!isChangeSubscription) return variants.data;

		return variants.data.filter((variant) => {
			if (profile.subscription.subscriptionType === SubscriptionType.MONTHLY) {
				return variant.id === variants.data[1]?.id; // Only show yearly plan
			}
			if (profile.subscription.subscriptionType === SubscriptionType.YEARLY) {
				return variant.id === variants.data[0]?.id; // Only show monthly plan
			}
			return true; // Show all plans for free users
		});
	};

	const availablePlans = getAvailablePlans();

	useEffect(() => {
		if (isSubscriptionModalOpen) {
			const modal = document.getElementById('subscription-modal') as HTMLDialogElement;
			if (modal) {
				modal.showModal();
			}
		}
	}, [isSubscriptionModalOpen]);

	useEffect(() => {
		const fetchVariants = async () => {
			try {
				setIsPlansLoading(true);
				const response = await getAllVariants().unwrap();
				if (response) {
					dispatch(getVariantsSuccess(response));
				}
			} catch (error) {
				if (isCustomErrorResponse(error)) {
					toast.error(error.data.message);
				} else if (error instanceof Error) {
					toast.error(error.message);
				} else {
					toast.error('An unknown error occurred');
				}
			} finally {
				setIsPlansLoading(false);
			}
		};
		if (variants.data.length > 0) return;
		fetchVariants();
	}, []);

	const checkoutUrl = async () => {
		try {
			setIsCheckoutUrlLoading(true);
			const response = await createCheckoutUrl(selectedPlan).unwrap();
			if (response) {
				window.open(response.data.attributes.url, '_blank');
				if (setHasRedirectedToCheckout) {
					setHasRedirectedToCheckout(true);
				}
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred');
			}
		} finally {
			setIsCheckoutUrlLoading(false);
			(document.getElementById('subscription-modal') as HTMLDialogElement).close();
			if (setIsSubscriptionModalOpen) {
				setIsSubscriptionModalOpen(false);
			}
			if (setIsChangeSubscription) {
				setIsChangeSubscription(false);
			}
		}
	};

	return (
		<dialog id={id} className="modal">
			<div className="modal-box p-0 border-2 border-lt-beige bg-lt-cream dark:bg-lt-dark-secondary-bg dark:border-lt-dark-popupBorder md:min-w-[900px] max-h-[80vh] overflow-y-auto">
				<form method="dialog">
					<button
						onClick={() => {
							setSelectedPlan({
								enabled_variants: [],
								expires_at: ''
							});
							if (setHasRedirectedToCheckout) {
								setHasRedirectedToCheckout(false);
							}
							(document.getElementById('subscription-modal') as HTMLDialogElement).close();
							if (setIsSubscriptionModalOpen) {
								setIsSubscriptionModalOpen(false);
							}
							if (setIsChangeSubscription) {
								setIsChangeSubscription(false);
							}
						}}
						className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
					>
						✕
					</button>
				</form>

				<div className="modal-action mt-0">
					<form method="dialog" className="flex flex-col md:flex-row w-full">
						<div className="bg-lt-lightCream dark:bg-lt-dark-primary-bg p-4 sm:p-6 min-h-[225px] md:min-h-[350px] md:h-full md:w-1/2">
							<h2 className="font-bold text-xl">Upgrade to Full Access</h2>
							<div className="flex flex-col gap-4 mt-2 sm:mt-5">
								<div className="flex items-start gap-2">
									<IconTableHeart className="min-w-5 min-h-5" />
									<div>
										<h4 className="text-base font-semibold">Unlimited Tables</h4>
										<h5 className="hidden md:block text-xs">
											You can create unlimited tables and store your data without any limitation.
										</h5>
									</div>
								</div>
								<div className="flex items-start gap-2">
									<IconPigMoney className="min-w-5 min-h-5" />
									<div>
										<h4 className="text-base font-semibold">Earn More Coins</h4>
										<h5 className="hidden md:block text-xs">
											As your streak increases, earn more coins. Unlock new stickers with the coins you earn and
											decorate your virtual journal!
										</h5>
									</div>
								</div>
								<div className="flex items-start gap-2">
									<IconFileAnalytics className="min-w-5 min-h-5" />
									<div>
										<h4 className="text-base font-semibold">Detailed Data</h4>
										<h5 className="hidden md:block text-xs">
											View detailed data for your tables and see how you've been doing over time.
										</h5>
									</div>
								</div>
								<div className="flex items-start gap-2">
									<IconLock className="min-w-5 min-h-5" />
									<div>
										<h4 className="text-base font-semibold">Secure Data Storage</h4>
										<h5 className="hidden md:block text-xs">
											Your data is securely stored even if you pause your subscription. When you resubscribe, you'll
											have full access to all your data without any loss, as long as you don't delete your account.
										</h5>
									</div>
								</div>
							</div>
						</div>
						<div className="bg-lt-cream dark:bg-lt-dark-secondary-bg flex flex-col justify-start p-4 sm:p-6 h-1/2 min-h-[225px] md:h-full md:w-1/2">
							<h2 className="font-bold text-xl">{isChangeSubscription ? 'Change your plan' : 'Choose your plan'}</h2>
							{isChangeSubscription && profile.subscription.subscriptionType !== SubscriptionType.FREE && (
								<h4 className="text-sm text-lt-dark-inputPlaceholder">
									You are currently subscribed to the <strong>{profile.subscription.subscriptionType}</strong> plan.
								</h4>
							)}
							<div className="mt-2 sm:mt-5 flex flex-col justify-between h-full">
								<div className="form-control flex flex-col h-2/3 justify-start">
									{isPlansLoading ? (
										<div className="flex justify-center items-center h-full">
											<span className="loading loading-spinner loading-md"></span>
										</div>
									) : (
										availablePlans.map((variant, _index) => (
											<label key={variant.id} className="label cursor-pointer flex justify-start gap-2">
												<input
													type="radio"
													name="plan"
													value={variant.id}
													className="radio checked:bg-lt-beige"
													checked={selectedPlan.enabled_variants[0] === variant.id}
													onChange={() => {
														setSelectedPlan({
															enabled_variants: [variant.id],
															expires_at: new Date(Date.now() + 60 * 60 * 1000).toISOString()
														});
													}}
												/>
												{variant.attributes.name === 'Monthly' ? (
													<div className="flex flex-col">
														<span className="label-text font-bold">Monthly - $3.99/mo</span>
														<span className="text-xs text-lt-dark-inputPlaceholder">(+VAT)</span>
													</div>
												) : (
													<div className="flex flex-col">
														<span className="label-text font-bold">
															Annual - <span className="line-through decoration-2 font-normal">$3.99/mo</span> $2.66/mo
														</span>
														<span className="text-xs text-lt-dark-inputPlaceholder">
															$31.99 - Billed annually (+VAT)
														</span>
													</div>
												)}
											</label>
										))
									)}
								</div>
								<div className="flex flex-col items-start gap-2">
									<Button
										variant="btn-primary-light"
										type="button"
										disabled={selectedPlan.enabled_variants.length === 0 || isCheckoutUrlLoading}
										onClick={() => {
											checkoutUrl();
										}}
										className="flex justify-center bg-lt-light-primary-bg text-lt-white border-lt-light-primary-border hover:bg-lt-light-primary-hover-bg px-4 py-2 rounded-lg w-full dark:bg-lt-dark-primary-bg dark:text-lt-dark-primary-text dark:border-lt-dark-primary-border dark:hover:bg-lt-dark-primary-hover-bg dark:hover:text-lt-dark-primary-text"
									>
										<div className="w-full flex justify-center">
											{isCheckoutUrlLoading ? (
												<span className="loading loading-spinner loading-xs"></span>
											) : isChangeSubscription ? (
												'Change Plan'
											) : (
												'Upgrade Now'
											)}
										</div>
									</Button>
									<h6 className="flex items-center gap-0.5 text-2xs text-lt-dark-inputPlaceholder whitespace-nowrap">
										Secure payment with{' '}
										<img
											src={theme === 'light' ? lemonSqueezyLogoDark : lemonSqueezyLogoLight}
											alt="Lemon Squeezy"
											className="w-20 dark:fill-lt-white"
										/>
										. Cancel anytime. No hidden fees.
									</h6>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</dialog>
	);
}
