import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../states/store';
import { useEffect } from 'react';
import { useLazyCheckQuery } from '../services/api/auth';
import { isAuthenticatedSuccess, logoutSuccess } from '../states/auth/authSlice';
import { isCustomErrorResponse } from '../utils/errorUtils';
import toast from 'react-hot-toast';

export default function Test() {
	const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
	const dispatch = useDispatch();
	const [checkIsAuthenticatedReq] = useLazyCheckQuery();

	useEffect(() => {
		const checkIsAuthenticated = async () => {
			try {
				const response = await checkIsAuthenticatedReq({}).unwrap();
				if (response) {
					dispatch(isAuthenticatedSuccess());
				}
			} catch (error) {
				if (isCustomErrorResponse(error)) {
					toast.error(error.data.message);
				} else if (error instanceof Error) {
					toast.error(error.message);
				} else {
					toast.error('An unknown error occurred');
				}
				dispatch(logoutSuccess());
				window.location.href = 'https://lifeontrack.co/auth/register';
			}
		};

		checkIsAuthenticated();
	}, [dispatch, isAuthenticated, checkIsAuthenticatedReq]);

	return (
		<div className="flex flex-col items-center">
			<span className="font-bold text-3xl">payment page</span>

			<a className="underline text-lt-beige" href="https://lifeontrack.co/dashboard/my-tables">
				Click here to go to dashboard
			</a>
		</div>
	);
}
