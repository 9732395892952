import { IconArrowLeft, IconArticle } from '@tabler/icons-react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

export default function GettingStarted() {
	return (
		<div className="flex flex-col items-center">
			<Helmet>
				<title>Getting Started | Life on Track</title>
				<meta
					name="description"
					content="Welcome to Life on Track! If you're new to our app, this guide will help you get started with tracking your habits, routines, and more."
				/>
			</Helmet>
			<Link to="/help" className="flex items-center absolute left-5 top-5 text-3xl font-bold text-lt-beige">
				<IconArrowLeft className="w-16 h-16 mr-2" />
			</Link>
			<div className="p-8 sm:p-40 pt-10 mt-10  max-w-[2000px] h-screen overflow-y-auto">
				<h1 className="font-bold text-3xl text-center">Getting Started</h1>
				<h2 className="text-md mt-4 text-center">
					Welcome to Life on Track! If you're new to our app, this guide will help you get started with tracking your
					habits, routines, and more.
				</h2>
				<div className="flex flex-col items-center justify-center mt-10">
					<Link
						to="/help/getting-started/life-on-track"
						className="w-full bg-lt-paleYellow dark:bg-lt-dark-popupBackground border border-lt-beige hover:bg-lt-beige hover:text-lt-lightCream p-4 rounded-xl"
					>
						<div className="flex items-center gap-2">
							<IconArticle className="w-5 h-5" />
							<h3 className="text-lg sm:text-2xl font-semibold">Getting Started With Life on Track</h3>
						</div>

						<p className="text-sm sm:text-md">
							Welcome to Life on Track! If you're new to our app, this guide will help you get started with tracking
							your habits, routines, and more...
						</p>
					</Link>
				</div>
			</div>
		</div>
	);
}
