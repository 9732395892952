import { useEffect, useState, useRef } from 'react';
import { IDataRange, ITable } from '../../../types/myTables.types';
import { Tooltip } from 'react-tooltip';
import Input from '../../input/input';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './NewDataRange.css';
import React from 'react';

interface NewDataRangeProps {
	newTableData: ITable;
	setNewTableData: React.Dispatch<React.SetStateAction<ITable>>;
	setLastSelectedColor: React.Dispatch<React.SetStateAction<string>>;
	createTableIsLoading: boolean;
	isTemplate?: boolean;
}

interface LocalDataRange extends IDataRange {
	firstPartOfRange?: string;
	secondPartOfRange?: string;
}

type DataRangeType = 'interval' | 'keyValue' | '';

export default function NewDataRange({
	newTableData,
	setNewTableData,
	setLastSelectedColor,
	createTableIsLoading,
	isTemplate
}: NewDataRangeProps) {
	const [dataRangeType, setDataRangeType] = useState<DataRangeType>('');
	const [localDataRanges, setLocalDataRanges] = useState<LocalDataRange[]>([]);
	const nodeRefs = useRef(localDataRanges.map(() => React.createRef<HTMLDivElement>()));

	useEffect(() => {
		if (isTemplate) {
			setDataRangeType(newTableData.dataRanges[0].unit === '' ? 'keyValue' : 'interval');
			if (newTableData.dataRanges[0].unit === '') {
				setLocalDataRanges(newTableData.dataRanges);
			} else if (newTableData.dataRanges[0].unit !== '') {
				const updatedDataRanges = newTableData.dataRanges.map((dataRange) => {
					const [firstPartOfRange, secondPartOfRange] = dataRange.range.split('-');
					const rangeValue =
						secondPartOfRange === undefined ? firstPartOfRange : `${firstPartOfRange}-${secondPartOfRange}`;

					return {
						...dataRange,
						firstPartOfRange: firstPartOfRange || rangeValue,
						secondPartOfRange: secondPartOfRange || ''
					};
				});
				setLocalDataRanges(updatedDataRanges);
			}
		}
	}, [isTemplate]);

	useEffect(() => {
		if (!isTemplate) {
			const updatedDataRanges = newTableData.dataRanges.map((dataRange) => ({
				...dataRange,
				firstPartOfRange: '',
				secondPartOfRange: ''
			}));
			setLocalDataRanges(updatedDataRanges);
		}
	}, []);

	useEffect(() => {
		if (dataRangeType === 'keyValue') {
			const handleUpdateDataRanges = () => {
				const lastRange = localDataRanges[localDataRanges.length - 1];
				const isLastRangeFilled = lastRange && lastRange.range !== '';

				if (isLastRangeFilled && localDataRanges.length < 6) {
					setLocalDataRanges((prevState) => [
						...prevState,
						{
							hexColorCode: generateRandomHexColor(),
							range: '',
							year: new Date().getFullYear().toString()
						}
					]);
					return;
				}

				const emptyRowIndexes = localDataRanges
					.map((dataRange, index) => ({ dataRange, index }))
					.filter(({ dataRange }) => dataRange.range === '')
					.map(({ index }) => index)
					.filter((index) => index >= 1);

				const hasFilledRowAfterEmptyRows = emptyRowIndexes.some((emptyIndex) =>
					localDataRanges.slice(emptyIndex + 1).some((range) => range.range !== '')
				);

				if (hasFilledRowAfterEmptyRows) {
					const firstEmptyRowIndex = emptyRowIndexes[0];
					const updatedLocalDataRanges = localDataRanges.filter((_, index) => index !== firstEmptyRowIndex);
					setLocalDataRanges(updatedLocalDataRanges);

					const updatedNewTableDataRanges = newTableData.dataRanges.filter((_, index) => index !== firstEmptyRowIndex);
					setNewTableData((prevState) => ({
						...prevState,
						dataRanges: updatedNewTableDataRanges
					}));
				} else if (emptyRowIndexes.length > 1) {
					const updatedLocalDataRanges = localDataRanges.filter((_, index) => {
						return !emptyRowIndexes.includes(index) || index === emptyRowIndexes[emptyRowIndexes.length - 1];
					});
					setLocalDataRanges(updatedLocalDataRanges);

					const updatedNewTableDataRanges = newTableData.dataRanges.filter((_, index) => {
						return !emptyRowIndexes.includes(index) || index === emptyRowIndexes[emptyRowIndexes.length - 1];
					});
					setNewTableData((prevState) => ({
						...prevState,
						dataRanges: updatedNewTableDataRanges
					}));
				} else {
					const dataRangesWithoutExtraFields = localDataRanges.map(({ range, ...rest }) => ({ ...rest, range }));
					setNewTableData((prevState) => ({
						...prevState,
						dataRanges: dataRangesWithoutExtraFields
					}));
				}
			};
			handleUpdateDataRanges();
		}
	}, [localDataRanges, dataRangeType]);

	useEffect(() => {
		if (dataRangeType === 'interval') {
			const handleUpdateDataRanges = () => {
				const lastRange = localDataRanges[localDataRanges.length - 1];
				const isLastRangeFilled =
					lastRange && lastRange.firstPartOfRange !== '' && lastRange.secondPartOfRange !== '' && lastRange.unit !== '';

				if (isLastRangeFilled && localDataRanges.length < 6) {
					setLocalDataRanges((prevState) => [
						...prevState,
						{
							hexColorCode: generateRandomHexColor(),
							range: '',
							unit: prevState[0].unit,
							firstPartOfRange: '',
							secondPartOfRange: '',
							year: new Date().getFullYear().toString()
						}
					]);
					return;
				}

				const emptyRowIndexes = localDataRanges
					.map((dataRange, index) => ({ dataRange, index }))
					.filter(({ dataRange }) => dataRange.firstPartOfRange === '' && dataRange.secondPartOfRange === '')
					.map(({ index }) => index)
					.filter((index) => (localDataRanges.length > 2 ? index >= 1 : index >= 2));

				const hasFilledRowAfterEmptyRows = emptyRowIndexes.some((emptyIndex) =>
					localDataRanges
						.slice(emptyIndex + 1)
						.some((range) => range.firstPartOfRange !== '' && range.secondPartOfRange !== '')
				);

				if (hasFilledRowAfterEmptyRows) {
					const firstEmptyRowIndex = emptyRowIndexes[0];
					const updatedLocalDataRanges = localDataRanges.filter((_, index) => index !== firstEmptyRowIndex);
					setLocalDataRanges(updatedLocalDataRanges);

					const updatedNewTableDataRanges = newTableData.dataRanges.filter((_, index) => index !== firstEmptyRowIndex);

					setNewTableData((prevState) => ({
						...prevState,
						dataRanges: updatedNewTableDataRanges
					}));
				} else if (emptyRowIndexes.length > 1) {
					const updatedLocalDataRanges = localDataRanges.filter((_, index) => {
						return !emptyRowIndexes.includes(index) || index === emptyRowIndexes[emptyRowIndexes.length - 1];
					});
					setLocalDataRanges(updatedLocalDataRanges);

					const updatedNewTableDataRanges = newTableData.dataRanges.filter((_, index) => {
						return !emptyRowIndexes.includes(index) || index === emptyRowIndexes[emptyRowIndexes.length - 1];
					});
					setNewTableData((prevState) => ({
						...prevState,
						dataRanges: updatedNewTableDataRanges
					}));
				} else {
					const dataRangesWithoutExtraFields = localDataRanges.map(
						({ firstPartOfRange, secondPartOfRange, ...rest }) => ({
							...rest,
							range:
								firstPartOfRange === '' && secondPartOfRange === '' ? '' : `${firstPartOfRange}-${secondPartOfRange}`
						})
					);

					setNewTableData((prevState) => ({
						...prevState,
						dataRanges: dataRangesWithoutExtraFields
					}));
				}
			};
			handleUpdateDataRanges();
		}
	}, [localDataRanges, dataRangeType]);

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '<', '>', '+', '-'];
		if (!allowedKeys.includes(e.key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
			e.preventDefault();
		}
	};

	const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
		const paste = e.clipboardData.getData('text');
		if (!/^[0-9<>+-]*$/.test(paste)) {
			e.preventDefault();
		}
	};

	const generateRandomHexColor = () => {
		const letters = '0123456789ABCDEF';
		let color = '';

		for (let i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return `#${color}`;
	};

	const handleRangeChange = (index: number, field: string, value: string) => {
		const updatedDataRanges = localDataRanges.map((dataRange, i) =>
			i === index ? { ...dataRange, [field]: value } : dataRange
		);
		setLocalDataRanges(updatedDataRanges);

		switch (field) {
			case 'unit':
				if (index === 0) {
					updatedDataRanges[0].unit = value;
					for (let i = 1; i < updatedDataRanges.length; i++) {
						updatedDataRanges[i].unit = value;
					}
					setLocalDataRanges(updatedDataRanges);
				}

				break;
			case 'range':
				updatedDataRanges[index].range = value;
				break;

			case 'firstPartOfRange':
				if (value === '') {
					updatedDataRanges[index].range = '';
				}
				break;
			case 'secondPartOfRange':
				if (value === '') {
					updatedDataRanges[index].range = '';
				}
				break;
			case 'hexColorCode':
				updatedDataRanges[index].hexColorCode = value;
				setLastSelectedColor(value);
				break;

			default:
				break;
		}
	};

	return (
		<div className="flex flex-col h-auto min-h-[200px] sm:min-h-[540px]">
			<label className="dark:text-lt-dark-primary-disabled-text">
				Will you use a certain interval or specify a key-value?
			</label>
			<div className="join w-full  lg:w-3/4 mt-2">
				<div className="flex flex-col w-1/2">
					<input
						disabled={createTableIsLoading}
						data-tooltip-id="interval-option-tooltip"
						data-tooltip-content={`Key values should be created from number ranges (e.g., 0-2, 2-5, 5+, <5)`}
						data-tooltip-place="top"
						className="join-item btn  w-full"
						checked={dataRangeType === 'interval'}
						type="radio"
						name="option"
						aria-label="Interval"
						value={'interval'}
						onChange={() => {
							const generatedColor1 = generateRandomHexColor();
							const generatedColor2 = generateRandomHexColor();
							setNewTableData({
								...newTableData,
								dataRanges: [
									{
										hexColorCode: generatedColor1,
										range: '',
										unit: '',
										year: new Date().getFullYear().toString()
									},
									{
										hexColorCode: generatedColor2,
										range: '',
										unit: '',
										year: new Date().getFullYear().toString()
									}
								]
							});
							setLocalDataRanges([
								{
									hexColorCode: generatedColor1,
									range: '',
									unit: '',
									firstPartOfRange: '',
									secondPartOfRange: '',
									year: new Date().getFullYear().toString()
								},
								{
									hexColorCode: generatedColor2,
									range: '',
									unit: '',
									firstPartOfRange: '',
									secondPartOfRange: '',
									year: new Date().getFullYear().toString()
								}
							]);
							setDataRangeType('interval');
							setLastSelectedColor(generatedColor1);
						}}
					/>
					<div className="hidden sm:block lg:hidden">
						<Tooltip id="interval-option-tooltip" />
					</div>

					<span className="sm:hidden lg:block text-lt-dark-secondary-disabled-text text-sm italic w-11/12">
						Use intervals to track numerical data with ranges. Perfect for tracking measurements.
					</span>
				</div>

				<div className="flex flex-col  w-1/2">
					<input
						disabled={createTableIsLoading}
						data-tooltip-id="keyValue-option-tooltip"
						data-tooltip-content={`Key values should be created from texts (e.g., happy, stressed, sunny, healthy)`}
						data-tooltip-place="top"
						className="join-item btn w-full rounded-r-lg"
						type="radio"
						checked={dataRangeType === 'keyValue'}
						name="option"
						aria-label="Key-Value"
						value={'keyValue'}
						onChange={() => {
							const generatedColor1 = generateRandomHexColor();
							const generatedColor2 = generateRandomHexColor();
							setNewTableData({
								...newTableData,
								dataRanges: [
									{
										hexColorCode: generatedColor1,
										range: '',
										unit: '',
										year: new Date().getFullYear().toString()
									},
									{
										hexColorCode: generatedColor2,
										range: '',
										unit: '',
										year: new Date().getFullYear().toString()
									}
								]
							});
							setLocalDataRanges([
								{
									hexColorCode: generatedColor1,
									range: '',
									unit: '',
									year: new Date().getFullYear().toString()
								},
								{
									hexColorCode: generatedColor2,
									range: '',
									unit: '',
									year: new Date().getFullYear().toString()
								}
							]);
							setDataRangeType('keyValue');
							setLastSelectedColor(generatedColor1);
						}}
					/>
					<div className="hidden sm:block lg:hidden">
						<Tooltip id="keyValue-option-tooltip" />
					</div>
					<span className="sm:hidden lg:block text-lt-dark-secondary-disabled-text text-sm italic w-full">
						Use key-values to track categorical or descriptive data.
					</span>
				</div>
			</div>

			{(dataRangeType === 'interval' || dataRangeType === 'keyValue') && (
				<div className="mt-4 ">
					<TransitionGroup className="grid gap-4">
						{localDataRanges.map((dataRange, index) => (
							<CSSTransition key={index} timeout={300} classNames="fade" nodeRef={nodeRefs.current[index]}>
								<div ref={nodeRefs.current[index]} className="flex">
									<input
										disabled={createTableIsLoading}
										maxLength={25}
										className="min-w-[50px] rounded-sm mr-2 "
										type="color"
										value={localDataRanges[index].hexColorCode}
										onChange={(e) => handleRangeChange(index, 'hexColorCode', e.target.value)}
									/>

									<div className="grid grid-cols-[1fr_auto_1fr_auto_1fr] gap-2 items-center">
										<Input
											maxLength={dataRangeType === 'interval' ? 10 : 25}
											onKeydown={dataRangeType === 'interval' ? handleKeyDown : undefined}
											onPaste={dataRangeType === 'interval' ? handlePaste : undefined}
											type="text"
											placeholder={dataRangeType === 'interval' ? 'First interval' : 'Key-Value'}
											className="p-2 border rounded-md placeholder:text-xs sm:placeholder:text-base"
											value={
												dataRangeType === 'interval'
													? localDataRanges[index].firstPartOfRange
													: localDataRanges[index].range
											}
											onChange={(e) => {
												if (dataRangeType === 'interval') {
													handleRangeChange(index, 'firstPartOfRange', e.target.value);
												} else if (dataRangeType === 'keyValue') {
													handleRangeChange(index, 'range', e.target.value);
												}
											}}
											disabled={
												createTableIsLoading || dataRangeType === 'interval'
													? index === localDataRanges.length ||
														(localDataRanges[0].unit === '' && index !== 0) ||
														((localDataRanges[0].firstPartOfRange === '' ||
															localDataRanges[0].secondPartOfRange === '') &&
															index !== 0)
													: index === localDataRanges.length
											}
										/>
										{dataRangeType === 'interval' && (
											<>
												<span>-</span>
												<Input
													maxLength={10}
													onKeydown={handleKeyDown}
													onPaste={handlePaste}
													type="text"
													placeholder="Second interval"
													className="p-2 border rounded-md placeholder:text-xs sm:placeholder:text-base"
													value={localDataRanges[index].secondPartOfRange}
													onChange={(e) => {
														handleRangeChange(index, 'secondPartOfRange', e.target.value);
													}}
													disabled={
														createTableIsLoading ||
														index === localDataRanges.length ||
														(localDataRanges[0].unit === '' && index !== 0) ||
														((localDataRanges[0].firstPartOfRange === '' ||
															localDataRanges[0].secondPartOfRange === '') &&
															index !== 0)
													}
												/>
												<span>,</span>
												{index === 0 ? (
													<Input
														disabled={createTableIsLoading}
														maxLength={15}
														value={localDataRanges[0].unit}
														name="unit"
														type="text"
														placeholder="Unit"
														className="p-2 border border-lt-beige rounded-md placeholder:text-xs sm:placeholder:text-base"
														onChange={(e) => handleRangeChange(index, 'unit', e.target.value)}
													/>
												) : (
													<div className="p-2 ">
														<span
															data-tooltip-id="dataRange-unit-tooltip"
															data-tooltip-content={dataRange.unit}
															data-tooltip-place="top"
														>
															{dataRange.unit && dataRange.unit.length > 10
																? `${dataRange.unit.substring(0, 10)}...`
																: dataRange.unit}
														</span>
														<Tooltip
															className={dataRange.unit && dataRange.unit?.length > 10 ? 'block' : 'hidden'}
															id="dataRange-unit-tooltip"
														/>
													</div>
												)}
											</>
										)}
									</div>
								</div>
							</CSSTransition>
						))}
					</TransitionGroup>
				</div>
			)}
		</div>
	);
}
