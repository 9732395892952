import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Logo from '/src/assets/logo.svg';

export default function TermsAndConditionsEn() {
	const pathname = window.location.pathname;
	const parts = pathname.split('/');
	const language = parts[1];

	return (
		<div>
			<Helmet>
				<title>Life on Track - Terms of Service</title>
				<meta name="description" content="Terms and conditions of Life on Track." />
			</Helmet>
			<header className="sticky top-0 z-50 w-full h-[70px] px-4 md:px-12 flex items-center justify-between border-b  bg-lt-lightCream dark:bg-lt-dark-secondary-bg">
				<div className="flex justify-start items-center md:gap-2 w-4/12 md:w-1/4">
					<img src={Logo} alt="logo" className="w-8 h-8 md:w-10 md:h-10" />
					<Link to="/" className="text-lt-beige dark:text-white font-extrabold text-sm sm:text-2xl">
						Life on Track
					</Link>
				</div>
				<div className="hidden md:flex justify-between base:justify-center base:gap-10 md:w-1/2">
					<Link
						to="/en/privacy-policy"
						className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
					>
						Privacy Policy
					</Link>
					<Link
						to="/en/refund-policy"
						className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
					>
						Refund Policy
					</Link>
					<Link
						to="/"
						className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
					>
						Home
					</Link>
				</div>
				<div className="navbar w-1/3 flex justify-center md:hidden">
					<div className="navbar-center md:hidden">
						<ul className="menu menu-md menu-horizontal px-1">
							<li>
								<details>
									<summary className="text-lt-beige dark:text-[#5D4037] active:bg-lt-lightCream active:text-lt-beige dark:active:bg-transparent">
										Menu
									</summary>
									<ul className="flex flex-col text-xs sm:text-base font-semibold justify-between text-black dark:text-white dark:bg-lt-dark-popupBackground  divide-y divide-lt-beige dark:divide-[#6E6E40] bg-lt-lightCream px-0 ">
										<li className="px-4">
											<Link
												to="/en/privacy-policy"
												className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
											>
												Privacy Policy
											</Link>
										</li>
										<li className="px-4">
											<Link
												to="/en/refund-policy"
												className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
											>
												Refund Policy
											</Link>
										</li>
										<li className="px-4">
											<Link
												to="/"
												className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
											>
												Home
											</Link>
										</li>
									</ul>
								</details>
							</li>
						</ul>
					</div>
				</div>
				<div className="flex justify-end gap-1 sm:gap-4 text-xs sm:text-base md:text-xl w-2/12 md:w-1/4">
					<Link
						className={`${language === 'en' ? 'text-lt-beige dark:text-[#5D4037]' : 'text-black dark:text-white'} hover:text-lt-beige
					}`}
						to="/en/terms-of-service"
					>
						EN
					</Link>
					/
					<Link
						className={`${language === 'tr' ? 'text-lt-beige dark:text-[#5D4037]' : 'text-black dark:text-white'} hover:text-lt-beige
					}`}
						to="/tr/terms-of-service"
					>
						TR
					</Link>
				</div>
			</header>
			<div className="p-6 max-w-4xl mx-auto mt-5 md:mt-10  h-screen overflow-y-auto pb-40">
				<div className="max-w-3xl p-6">
					<h2 className="text-2xl font-bold mb-4 text-center">USER AGREEMENT</h2>
					<h2 className="text-2xl font-bold mb-4 text-center">TERMS AND CONDITIONS</h2>
					<h3 className="text-xl font-semibold mt-6 mb-3">1. PARTIES, SUBJECT AND SCOPE</h3>
					<p className="text-gray-700 dark:text-white mb-4">
						This user agreement (“<strong>Agreement</strong>”) is made between Kaan Mert (“
						<strong>Kaan Software Studio</strong>”), located at “Yağcılar Mahallesi Saadet Sokağı No: 18/- Adapazarı/
						Sakarya”, and individuals (“<strong>User</strong>”) who visit and use the Life on Track website (“
						<strong>Life on Track</strong>”) under the conditions specified in this Agreement. The subject of this
						Agreement is to determine the terms of use of Life on Track and to regulate the rights and obligations of
						the User and Kaan Software Studio (“<strong>Parties</strong>”).
					</p>
					<p className="text-gray-700 dark:text-white mb-4">
						By visiting the Life on Track website, the User is deemed to have accepted all declarations made by Kaan
						Software Studio regarding usage, membership, and services, as well as the terms and conditions of this
						Agreement. The User agrees, declares, and undertakes to comply with all matters specified in these
						declarations.
					</p>
					<h3 className="text-xl font-semibold mt-6 mb-3">2. DEFINITIONS</h3>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>2.1</strong> In this Agreement: <br />
						<strong>2.1.1 Subscription:</strong> Refers to the paid service offered within Life on Track through Monthly
						or Yearly subscription options. <br />
						<strong>2.1.2 Payment Processor:</strong> Refers to third-party payment processors used by Kaan Software
						Studio to issue invoices to users. <br />
						<strong>2.1.3 System Access Tools:</strong> Refers to information known only by the User, such as usernames
						and passwords, that provide the User with access to Life on Track. <br />
						<strong>2.1.4 Non-Subscriber:</strong> Refers to users who access Life on Track without an active
						subscription.
					</p>
					<h3 className="text-xl font-semibold mt-6 mb-3">3. RIGHTS AND OBLIGATIONS OF THE PARTIES</h3>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>3.1</strong> The User agrees to act in accordance with all terms included in this Agreement, the
						stated rules, and all applicable regulations while benefiting from the products or services on Life on Track
						and performing any actions, and acknowledges that they have read, understood, and accepted all terms and
						rules stated in this Agreement.
					</p>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>3.2</strong> The User declares that Kaan Software Studio shall be authorized to disclose the User's
						commercial information to business partners with whom it has contracted for the performance of the products
						and services covered by this Agreement, and the User accepts this.
					</p>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>3.3</strong> The User acknowledges and declares that all personal data they have provided and chosen
						to share in the tables on Life on Track is shared entirely of their own volition.
					</p>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>3.4</strong> The User may request Kaan Software Studio to delete the data held about them at any
						time within the scope of the Clarification Text and Privacy Policy.
					</p>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>3.5</strong> The User may purchase access to all services offered by Life on Track through Monthly
						or Yearly Membership options available on Life on Track.
					</p>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>3.6</strong> Kaan Software Studio provides the User with the services of creating and using tables
						and images to record aspects of their daily life ("<strong>Services</strong>") through Life on Track.
					</p>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>3.7</strong> Kaan Software Studio reserves the right to unilaterally change and reorganize the
						information, products, services, and content offered on Life on Track, as well as the terms of use, at any
						time without any notice or grace period if deemed appropriate.
					</p>
					<h3 className="text-xl font-semibold mt-6 mb-3">4. PRICING AND MEMBERSHIP TYPES</h3>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>4.1</strong> Life on Track offers both free and subscription-based access. Non-subscribers can only
						view tables but cannot create any tables or earn in-app coins. Subscribers can choose from Monthly or Yearly
						subscription options, with the all-inclusive sales price (including all taxes) subject to the User's
						additional approval. Monthly subscriptions provide full access for 1 month, Yearly subscriptions for 1 year.
					</p>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>4.2</strong> The User accepts that no refunds can be made as the offered service falls under the
						"electronically delivered product" category.
					</p>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>4.3</strong> Kaan Software Studio may use a third-party payment processor ("
						<strong>Payment Processor</strong>") to issue invoices for purchases made on Life on Track. The payment
						process will be subject to the terms, conditions, and privacy policies of the Payment Processor, in addition
						to this Agreement. Kaan Software Studio is not liable for any damages and/or outcomes caused by the Payment
						Processor.
					</p>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>4.4</strong> The terms related to payments will be subject to the contractual provisions between the
						User and their chosen payment method or credit card providers or financial institutions.
					</p>
					<h3 className="text-xl font-semibold mt-6 mb-3">5. RIGHT OF WITHDRAWAL AND TERMINATION</h3>
					<p className="text-gray-700 dark:text-white mb-4">
						The User does not have the right of withdrawal for the service provided, as it falls under the scope of
						Article 15, Paragraph 1, Subparagraph (h) of the Regulation on Distance Contracts titled "Exceptions to the
						Right of Withdrawal," which states "contracts relating to services that have begun with the consumer's
						approval before the end of the withdrawal period," as well as Article 15, Paragraph 1, Subparagraph (ğ)
						stating "contracts relating to services performed instantly in an electronic environment and intangible
						goods delivered instantly to the consumer." However, the User has the right to terminate the Agreement at
						any time without giving any reason and without paying a penalty. In case of termination, the amount already
						paid will not be refunded, and the User may continue to benefit from the service until the end of the
						membership period.
					</p>
					<h3 className="text-xl font-semibold mt-6 mb-3">6. COMPETENT COURT</h3>
					<p className="text-gray-700 dark:text-white mb-4">
						Turkish law shall apply when resolving disputes arising out of interpretation or execution of this
						Agreement. Turkish Consumer Arbitration Committees (up to the value announced by the Ministry of Industry
						and Trade) and the Consumer Courts in the place of residence of the the Buyer or the Seller have
						jurisdiction regarding disputes arising out of this Agreement.
					</p>
					<h3 className="text-xl font-semibold mt-6 mb-3">7. ENFORCEMENT AND OTHER PROVISIONS</h3>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>7.1</strong> The User declares, accepts, and undertakes that they have read, understood, and agreed
						to all the provisions of this Agreement and that the information provided by them is correct.
					</p>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>7.2</strong> This Agreement has been mutually accepted and entered into force with the electronic
						approval of the User's membership request.
					</p>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>7.3</strong> This Agreement consists of 7 (seven) articles and has been established with the free
						will of the parties as a result of the User's approval of the Agreement available on Life on Track.
					</p>
				</div>
			</div>
		</div>
	);
}
