import { IconCheck, IconX } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

export default function PasswordPopup({ password }: { password: string }) {
	const [passwordRequirements, setPasswordRequirements] = useState({
		length: false
		// uppercase: false,
		// lowercase: false,
		// number: false,
		// specialChar: false,
	});

	useEffect(() => {
		setPasswordRequirements({
			length: password.length >= 8
			// uppercase: /[A-Z]/.test(password),
			// lowercase: /[a-z]/.test(password),
			// number: /[0-9]/.test(password),
			// specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
		});
	}, [password]);

	return (
		<div className="relative">
			<div className="absolute left-0 top-1/2 transform -translate-y-1/2 ml-4">
				<div className="relative bg-lt-light-popupBackground text-lt-light-popupText border border-lt-light-popupBorder dark:bg-lt-dark-popupBackground dark:text-lt-dark-popupText dark:border-lt-dark-popupBorder py-4 px-6 rounded-xl shadow-xl w-64">
					<div className="absolute -left-4 top-1/2 mt-6 transform -translate-y-1/2">
						<div className="w-0 h-0 border-t-8 border-b-8 border-r-8 border-r-lt-light-popupBackground border-t-transparent border-b-transparent dark:border-r-lt-dark-popupBackground"></div>
					</div>
					<ul className="space-y-2">
						<li className="flex items-center">
							{passwordRequirements.length ? (
								<IconCheck className="text-green-500" />
							) : (
								<IconX className="text-red-500" />
							)}
							<span className="ml-2">At least 8 characters</span>
						</li>
						{/* <li className="flex items-center">
              {passwordRequirements.uppercase ? (
                <IconCheck className="text-green-500" />
              ) : (
                <IconX className="text-red-500" />
              )}
              <span className="ml-2">One uppercase letter</span>
            </li>
            <li className="flex items-center">
              {passwordRequirements.lowercase ? (
                <IconCheck className="text-green-500" />
              ) : (
                <IconX className="text-red-500" />
              )}
              <span className="ml-2">One lowercase letter</span>
            </li>
            <li className="flex items-center">
              {passwordRequirements.number ? (
                <IconCheck className="text-green-500" />
              ) : (
                <IconX className="text-red-500" />
              )}
              <span className="ml-2">One number</span>
            </li>
            <li className="flex items-center">
              {passwordRequirements.specialChar ? (
                <IconCheck className="text-green-500" />
              ) : (
                <IconX className="text-red-500" />
              )}
              <span className="ml-2">One special character</span>
            </li> */}
					</ul>
				</div>
			</div>
		</div>
	);
}
