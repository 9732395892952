import { Helmet } from 'react-helmet';
import './style.css';
export default function NotFound() {
	return (
		<div>
			<Helmet>
				<title>Life on Track - 404</title>
				<meta name="description" content="Page not found" />
			</Helmet>
			<div className="center">
				<div className="page dark:text-black">
					<h1>404</h1>
					<p>
						<b>Page Not Found</b>
					</p>
					<p>The page you are looking for is missing. Please make sure you are on the right URL</p>
					<p>or</p>
					<p>Click the button below to go back to the homepage</p>
					<a href="/dashboard/my-tables" className="btn btn-primary-light mt-10">
						Back to Home{' '}
					</a>
				</div>
			</div>
		</div>
	);
}
