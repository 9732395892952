import {
	BaseQueryFn,
	FetchArgs,
	fetchBaseQuery,
	FetchBaseQueryError,
	FetchBaseQueryMeta
} from '@reduxjs/toolkit/query/react';
import { logoutSuccess } from '../../states/auth/authSlice';
import { persistor, resetAction } from '../../states/store';
import toast from 'react-hot-toast';

export const baseQuery = fetchBaseQuery({
	baseUrl:
		process.env.NODE_ENV === 'development' ? import.meta.env.VITE_API_URL_TEST : import.meta.env.VITE_API_URL_PROD,
	credentials: 'include'
});

let toastPromise: Promise<void> | null = null;

export const baseQueryWithReauth: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError,
	{},
	FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
	const result = await baseQuery(args, api, extraOptions);
	console.log('result', result);

	if (result.error && result.error.status === 401) {
		api.dispatch(logoutSuccess());
		api.dispatch(resetAction());
		await persistor.purge();

		if (!toastPromise) {
			toastPromise = new Promise((resolve) => {
				toast.error('Session expired. Please log in again.');
				setTimeout(() => {
					window.location.href = '/auth/login';
					toastPromise = null;
					resolve();
				}, 2000);
			});
		}
	}

	return result;
};
