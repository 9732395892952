import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IVariantResponse } from '../../services/api/payment';

interface PaymentState {
	variants: IVariantResponse;
}

const initialState: PaymentState = {
	variants: {
		meta: {
			page: {
				currentPage: 0,
				from: 0,
				lastPage: 0,
				perPage: 0,
				to: 0,
				total: 0
			}
		},
		data: []
	}
};

const paymentSlice = createSlice({
	name: 'payment',
	initialState,
	reducers: {
		getVariantsSuccess(state, action: PayloadAction<IVariantResponse>) {
			state.variants = action.payload;
		}
	}
});

export const { getVariantsSuccess } = paymentSlice.actions;
export default paymentSlice.reducer;
