import { ITable } from '../../../types/myTables.types';
import { IconPlus } from '@tabler/icons-react';
import Button from '../../../components/button/button';
import { useTheme } from '../../../context/ThemeContext';
import { SubscriptionType } from '../../../services/api/profile';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../states/store';
import Subscription from '../Subscription/Subscription';
import { useEffect, useState } from 'react';
import tableTemplatesWithUnit from './tableTemplatesWithUnit.json';
import tableTemplatesWithoutUnit from './tableTemplatesWithoutUnit.json';

export default function TableTemplates() {
	const { theme } = useTheme();
	const [templatesWithUnit, setTemplatesWithUnit] = useState<ITable[]>([]);
	const [templatesWithoutUnit, setTemplatesWithoutUnit] = useState<ITable[]>([]);
	const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
	const [hasRedirectedToCheckout, setHasRedirectedToCheckout] = useState(false);

	const getImagePath = (imageName: string, theme: string) => {
		return `/tableTemplates/${theme}Theme/${imageName}`;
	};

	useEffect(() => {
		const updatedTemplatesWithUnit = tableTemplatesWithUnit.map((template) => ({
			...template,
			dataRanges: template.dataRanges.map((range) => ({
				...range,
				year: new Date().getFullYear().toString()
			})),
			img: getImagePath(template.img, theme)
		}));
		setTemplatesWithUnit(updatedTemplatesWithUnit);

		const updatedTemplatesWithoutUnit = tableTemplatesWithoutUnit.map((template) => ({
			...template,
			dataRanges: template.dataRanges.map((range) => ({
				...range,
				year: new Date().getFullYear().toString()
			})),
			img: getImagePath(template.img, theme)
		}));
		setTemplatesWithoutUnit(updatedTemplatesWithoutUnit);
	}, [theme]);

	const { profile } = useSelector((state: RootState) => state.profile);
	const navigate = useNavigate();

	const closeCreateTableModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		(document.getElementById('create_table_modal') as HTMLDialogElement)?.close();
	};

	const createTable = () => {
		if (profile?.subscription.subscriptionType === SubscriptionType.FREE && profile.subscription.isTrial === false) {
			setIsSubscriptionModalOpen(true);
		} else {
			navigate('/dashboard/my-tables/0?isCreate=true');
		}
	};

	const chooseTemplate = (table: ITable) => {
		navigate('/dashboard/my-tables/0?isCreate=true', { state: { templateData: table } });
	};

	return (
		<>
			{hasRedirectedToCheckout && (
				<div className="fixed inset-0 z-50 bg-black bg-opacity-50">
					<div className="bg-lt-lightCream dark:bg-lt-dark-secondary-bg p-4 flex flex-col items-center gap-2 absolute top-0 left-0 right-0">
						<p className="text-lt-dark-primary-text dark:text-lt-dark-primary-text text-center">
							Please refresh the page to ensure the latest updates are displayed.
						</p>
						<Button
							variant="btn-primary-light"
							onClick={() => window.location.reload()}
							className="text-sm px-4 py-1"
							type={'button'}
						>
							Refresh Page
						</Button>
					</div>
				</div>
			)}
			<dialog id="create_table_modal" className="modal">
				{isSubscriptionModalOpen && (
					<Subscription
						id="subscription-modal"
						setIsSubscriptionModalOpen={setIsSubscriptionModalOpen}
						setHasRedirectedToCheckout={setHasRedirectedToCheckout}
					/>
				)}
				<div className="p-0 modal-box max-w-4xl max-h-[700px] md:min-h-[800px] bg-lt-light-popupBackground dark:bg-lt-dark-popupBackground border border-lt-light-popupBorder dark:border-lt-dark-popupBorder rounded-md flex flex-col">
					<div className="flex-none sticky top-0 bg-lt-light-popupBackground dark:bg-lt-dark-popupBackground p-6 z-50">
						<h3 className="font-bold text-3xl">Create Table</h3>
						<form method="dialog">
							<button
								onClick={closeCreateTableModal}
								className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
							>
								✕
							</button>
						</form>
					</div>
					<div className="flex-1 overflow-y-auto">
						<div className="flex flex-col gap-5 pl-5 p-2 py-10">
							<div className="flex w-full justify-center">
								<Button onClick={createTable} type={'button'} variant="btn-primary-light">
									Create Custom Table
								</Button>
							</div>
							<div>
								<div className="flex items-center justify-center my-4 w-full">
									<div className="w-1/2 h-px bg-lt-beige dark:bg-lt-dark-primary-border"></div>
									<span className="mx-4 text-lt-beige font-medium dark:text-lt-dark-inputText">OR</span>
									<div className="w-1/2 h-px bg-lt-beige dark:bg-lt-dark-primary-border"></div>
								</div>
							</div>
							<div className="flex flex-col w-full items-center">
								<h6 className="font-bold text-xl text-center">Choose a template.</h6>
								<h6 className="font-bold text-xl text-center">You can customize it after choosing a template.</h6>
							</div>
							<div>
								<div className="flex flex-col gap-2 p-4">
									<label
										className="font-bold text-lt-beige dark:text-lt-dark-inputText text-xl"
										htmlFor="TableTemplatesWithUnit"
									>
										Templates with Intervals
									</label>
									<div className="grid grid-cols-1 md:grid-cols-2 gap-3">
										{templatesWithUnit.map((table, index) => (
											<div
												id="TableTemplatesWithUnit"
												key={index}
												className="relative border border-lt-beige dark:border-lt-dark-primary-border rounded-md p-4"
											>
												<div className="flex justify-start items-center text-base sm:text-xl gap-2 w-full">
													<i className={`${table.icon}`}></i>
													<h6 className="font-bold">{table.title}</h6>
												</div>
												<div className="w-full sm:w-10/12 flex justify-start">
													<img className="w-[300px] h-[450px]" src={table.img} alt="Table Template" />
												</div>
												<button
													onClick={() => {
														if (
															profile?.subscription.subscriptionType === SubscriptionType.FREE &&
															profile.subscription.isTrial === false
														) {
															setIsSubscriptionModalOpen(true);
															return;
														}
														chooseTemplate(table);
													}}
													className="bg-transparent absolute inset-0 hover:bg-lt-beige dark:hover:bg-lt-dark-primary-bg dark:hover:bg-opacity-30 hover:bg-opacity-30 p-4 flex flex-col justify-center items-center text-white font-bold text-center group"
												>
													<IconPlus className="bg-lt-beige dark:bg-lt-dark-primary-bg opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-in-out rounded-full w-10 h-10" />
												</button>
											</div>
										))}
									</div>
								</div>
								<div className="flex flex-col gap-2 p-4 pb-10">
									<label
										className="font-bold text-lt-beige dark:text-lt-dark-inputText text-xl"
										htmlFor="TableTemplatesWithoutUnit"
									>
										Templates with Key-Values
									</label>
									<div className="grid grid-cols-1 md:grid-cols-2 gap-3">
										{templatesWithoutUnit.map((table, index) => (
											<div
												id="TableTemplatesWithoutUnit"
												key={index}
												className="relative border-2 border-lt-beige dark:border-lt-dark-primary-border rounded-md p-4"
											>
												<div className="flex justify-start items-center text-base sm:text-xl gap-2 w-full">
													<i className={`${table.icon}`}></i>
													<h6 className="font-bold">{table.title}</h6>
												</div>
												<div className="w-full sm:w-10/12 flex justify-start">
													<img className="w-[300px] h-[450px]" src={table.img} alt="Table Template" />
												</div>
												<button
													onClick={() => {
														if (
															profile?.subscription.subscriptionType === SubscriptionType.FREE &&
															profile.subscription.isTrial === false
														) {
															setIsSubscriptionModalOpen(true);
															return;
														}
														chooseTemplate(table);
													}}
													className="bg-transparent absolute inset-0 hover:bg-lt-beige hover:bg-opacity-30 dark:hover:bg-lt-dark-primary-bg dark:hover:bg-opacity-30 p-4 flex flex-col justify-center items-center text-white font-bold text-center group"
												>
													<IconPlus className="bg-lt-beige dark:bg-lt-dark-primary-bg opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-in-out rounded-full w-10 h-10" />
												</button>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</dialog>
		</>
	);
}
