import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISticker } from '../../types/sticker.types';

interface Pagination {
	page: number;
	totalPages: number;
	totalShopItems: number;
}

interface ShopItemState {
	shopItems: { [page: number]: ISticker[] };
	loadedPages: number[];
	pagination: Pagination;
	searchQuery?: string;
}

const initialState: ShopItemState = {
	shopItems: {},
	loadedPages: [],
	pagination: { page: 1, totalPages: 1, totalShopItems: 0 },
	searchQuery: ''
};

const shopItemsSlice = createSlice({
	name: 'shopItems',
	initialState,
	reducers: {
		getShopItemsSuccess(
			state,
			action: PayloadAction<{
				items: ISticker[];
				pagination: Pagination;
				query: string;
			}>
		) {
			const { items, pagination, query } = action.payload;

			state.shopItems[pagination.page] = items;
			if (!state.loadedPages.includes(pagination.page)) {
				state.loadedPages.push(pagination.page);
			}

			state.pagination = pagination;
			state.searchQuery = query;
		},
		// setSort(state, action: PayloadAction<{ sortBy: string; order: string }>) {
		// 	state.sort = action.payload;
		// },
		// setSearch(state, action: PayloadAction<{ query: string }>) {
		// 	state.search = action.payload;
		// },
		clearSearchQuery(state) {
			state.searchQuery = '';
		}
	}
});

export const { getShopItemsSuccess, setSort, setSearch, clearSearchQuery } = shopItemsSlice.actions;
export default shopItemsSlice.reducer;
