import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import Input from '../../components/input/input';
import Button from '../../components/button/button';
import {
	useGoogleLoginMutation,
	useLazyHandleFacebookAuthQuery,
	useLoginMutation,
	useResendVerificationEmailMutation
} from '../../services/api/auth';
import { isCustomErrorResponse } from '../../utils/errorUtils';
import { LoginRequest } from '../../types/auth.types';
import { loginSuccess } from '../../states/auth/authSlice';
import { useDispatch } from 'react-redux';
import { GoogleLogin } from '@react-oauth/google';
import { persistor, resetAction } from '../../states/store';
import { Helmet } from 'react-helmet';
import Logo from '/src/assets/logo.svg';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { FacebookLoginButton } from 'react-social-login-buttons';

export default function Login() {
	const dispatch = useDispatch();
	const [loginData, setLoginData] = useState<LoginRequest>({
		email: '',
		password: ''
	});
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [login, { isLoading }] = useLoginMutation();
	const [googleLoginRequest, { isLoading: isGoogleLoading }] = useGoogleLoginMutation();
	const [resendVerificationEmail] = useResendVerificationEmailMutation();
	const [handleFacebookAuth, { isLoading: isFacebookAuthLoading }] = useLazyHandleFacebookAuthQuery();
	const emailInputRef = useRef<HTMLInputElement>(null);

	const navigate = useNavigate();

	useEffect(() => {
		if (emailInputRef.current) {
			emailInputRef.current.focus();
		}
	}, []);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setLoginData((prevState) => ({
			...prevState,
			[name]: value
		}));
	};
	const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		try {
			const response = await login(loginData).unwrap();

			if (response) {
				dispatch(resetAction());
				await persistor.purge();
				dispatch(loginSuccess({ email: loginData.email }));
				navigate('/');
			}
		} catch (error: any) {
			if (isCustomErrorResponse(error)) {
				if (error.data.validationErrors) {
					error.data.validationErrors.forEach((validationError) => {
						const constraints = validationError.constraints;
						const messages = Object.values(constraints).join('. ');
						toast.error(messages);
					});
				} else {
					toast.error(error.data.message);
				}
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
			if (error?.data?.resendLink) {
				await resendVerificationEmail({ email: loginData.email }).unwrap();
			}
		}
	};

	const handleLoginSuccess = async (response: any) => {
		try {
			const res = await googleLoginRequest({
				token: response.credential
			}).unwrap();
			if (res) {
				dispatch(loginSuccess({ email: res.email }));
				navigate('/dashboard/my-tables');
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		}
	};

	const handleFacebookLogin = async () => {
		window.location.href = `${import.meta.env.VITE_API_URL_PROD}/auth/facebook`;
	};

	return (
		<div className="relative flex min-h-screen flex-col items-center justify-center">
			<Helmet>
				<title>Life on Track - Login</title>
				<meta name="description" content="Login to Life on Track" />
			</Helmet>
			<div className="absolute top-0 left-0 w-full flex justify-start p-2 sm:p-4 items-center gap-2">
				<img src={Logo} alt="logo" className="w-10 h-10" />
				<Link to="/" className="text-lt-beige dark:text-white font-extrabold text-2xl">
					Life on Track
				</Link>
			</div>
			<div className="flex flex-col items-center py-6 sm:py-10 gap-2 sm:gap-4 w-full box-border bg-white dark:bg-lt-dark-popupBackground max-w-[700px] sm:min-h-[725px] border border-lt-beige dark:border-lt-dark-primary-border rounded-md sm:rounded-lg sm:shadow-xl">
				<h2 className="font-bold text-4xl sm:text-5xl mb-4">Login</h2>
				<GoogleLogin
					locale="en"
					text="signin_with"
					size="large"
					onSuccess={handleLoginSuccess}
					onError={() => {
						console.log('Signin Failed');
					}}
				/>
				<LoginSocialFacebook
					className="w-[187px] flex justify-center items-center m-0"
					appId={import.meta.env.VITE_FACEBOOK_APP_ID}
					onResolve={handleFacebookLogin}
					onReject={(error) => {
						console.log(error);
						toast.error('Facebook signin failed');
					}}
				>
					<FacebookLoginButton className="text-xs m-0 h-[40px]" text="Sign in with Facebook" />
				</LoginSocialFacebook>
				<div className="flex items-center justify-center my-4 w-full">
					<div className="w-1/4 h-px bg-lt-beige dark:bg-lt-dark-primary-border"></div>
					<span className="mx-4 text-gray-500 font-medium">OR</span>
					<div className="w-1/4 h-px bg-lt-beige dark:bg-lt-dark-primary-border"></div>
				</div>
				<form
					onSubmit={handleLogin}
					className="flex flex-col justify-between items-center gap-4 w-full min-h-[368px] max-w-xs"
				>
					<div className="w-full flex flex-col items-center gap-4">
						<Input
							type="email"
							placeholder="Email"
							name="email"
							required
							value={loginData.email}
							onChange={handleChange}
							ref={emailInputRef}
							className="w-full"
						/>
						<div className="w-full flex flex-col items-start max-w-xs">
							<Input
								type={isPasswordVisible ? 'text' : 'password'}
								value={loginData.password}
								onChange={handleChange}
								placeholder="Password"
								name="password"
								className="w-full"
								icon={
									isPasswordVisible ? (
										<IconEyeOff onClick={() => setIsPasswordVisible(false)} className="h-5 w-5 cursor-pointer" />
									) : (
										<IconEye onClick={() => setIsPasswordVisible(true)} className="h-5 w-5 cursor-pointer" />
									)
								}
							/>
							<Link
								to={'/auth/forgot-password'}
								className="flex w-full justify-end underline mt-1 text-lt-beige text-sm"
							>
								Forgot Password?
							</Link>
						</div>
					</div>
					{isLoading || isGoogleLoading || isFacebookAuthLoading ? (
						<button
							disabled
							className="btn btn-square w-full mt-4 bg-lt-light-primary-disabled-bg dark:bg-lt-dark-primary-disabled-bg"
						>
							<span className="loading loading-spinner"></span>
						</button>
					) : (
						<Button
							className="w-full mt-4"
							disabled={
								loginData.email == '' ||
								loginData.password == '' ||
								isLoading ||
								isGoogleLoading ||
								isFacebookAuthLoading
							}
							type="submit"
							variant="btn-primary-light"
						>
							Login
						</Button>
					)}
				</form>

				<h6>
					Don't have an account?{' '}
					<Link to={'/auth/register'} className="underline text-lt-beige">
						Register
					</Link>
				</h6>
				{/* <h6>
					<Link to="/auth/whitelist" className="underline">
						Please enter your email for the whitelist request.
					</Link>
				</h6> */}
			</div>
		</div>
	);
}
