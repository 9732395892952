import { Outlet, useNavigate } from 'react-router-dom';
import Sidebar from '../../../components/sidebar/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../states/store';
import { useEffect } from 'react';
import BottomMenu from '../../../components/bottomMenu/BottomMenu';
import TopMenu from '../../../components/topMenu/TopMenu';
import { isAuthenticatedSuccess } from '../../../states/auth/authSlice';
import { useLazyCheckQuery } from '../../../services/api/auth';
import { isCustomErrorResponse } from '../../../utils/errorUtils';
import toast from 'react-hot-toast';

export const DashboardLayout = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
	const [checkIsAuthenticatedReq] = useLazyCheckQuery();

	useEffect(() => {
		const checkIsAuthenticated = async () => {
			try {
				const response = await checkIsAuthenticatedReq({}).unwrap();
				if (response) {
					dispatch(isAuthenticatedSuccess());
				}
			} catch (error) {
				if (isCustomErrorResponse(error)) {
					toast.error(error.data.message);
				} else if (error instanceof Error) {
					toast.error(error.message);
				} else {
					toast.error('An unknown error occurred');
				}
				navigate('/auth/login');
			}
		};

		if (isAuthenticated) return;
		checkIsAuthenticated();
	}, []);

	return (
		<div className="dashboard-layout flex max-h-screen overflow-hidden">
			{/* Sidebar */}
			<div className="hidden md:flex">
				<Sidebar />
			</div>

			{/* Main Content */}
			<div className="flex-1 flex flex-col overflow-hidden relative">
				{/* Top Menu */}
				<div className="md:hidden flex-shrink-0 h-16">
					<TopMenu />
				</div>

				{/* Outlet */}
				<div className="flex-1 overflow-y-hidden md:m-4 bg-lt-cream dark:bg-lt-dark-secondary-bg md:border-2 md:border-lt-beige dark:border-lt-dark-secondary-border rounded">
					<Outlet />
				</div>

				{/* Bottom Menu */}
				<div className="md:hidden flex-shrink-0 h-14">
					<BottomMenu />
				</div>
			</div>
		</div>
	);
};
