import { IconCrown, IconSquareCheckFilled } from '@tabler/icons-react';
import { useState } from 'react';
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../states/store';
import { SubscriptionType } from '../../../services/api/profile';

const proPlan = [
	{
		icon: <IconSquareCheckFilled />,
		desc: 'Unlimited table creation.'
	},
	{
		icon: <IconSquareCheckFilled />,
		desc: 'Earn coins through streak bonuses.'
	},
	{
		icon: <IconSquareCheckFilled />,
		desc: 'Access to detailed data for your tables.'
	},
	{
		icon: <IconSquareCheckFilled />,
		desc: 'Your data is securely stored even if you pause your subscription.'
	},
	{
		icon: <IconSquareCheckFilled />,
		desc: 'Access to the virtual journal and the ability to decorate it with stickers.'
	},
	{
		icon: <IconSquareCheckFilled />,
		desc: 'View the activities and journals of users you follow.'
	}
];

export default function Pricing() {
	const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
	const profile = useSelector((state: RootState) => state.profile.profile);
	const [isToggled, setIsToggled] = useState(false);
	const [text, setText] = useState<'Monthly' | 'Yearly'>('Monthly');

	const toggle = () => {
		setIsToggled(!isToggled);
	};

	return (
		<Element name="pricing" className="w-full md:w-2/4 flex flex-col text-center gap-3 box-border px-4 ">
			<header className="font-bold text-3xl sm:text-5xl dark:text-white">Pricing</header>
			<h2 className="sm:font-semibold text-base sm:text-xl dark:text-[#BDBDBD]">
				Try Complete Plan free for 7 days and unlock all features. Start tracking your life with no commitment!
			</h2>
			<div className="flex flex-col items-center md:items-stretch md:flex-row gap-4">
				<div className="w-full pt-4">
					<div className="border border-lt-beige dark:border-[#6E6E40] rounded-lg h-full text-start bg-gradient-to-tr from-lt-beige via-[#e9ccab] dark:from-[#121212] dark:to-lt-dark-popupBackground to-lt-lightCream box-border p-4 sm:p-6">
						<div className="flex flex-col items-center">
							<div className="w-full flex justify-between">
								<div className="flex items-center">
									<h4 className="text-[#8E4600] dark:text-white font-bold text-xl sm:text-2xl">Subscription</h4>
									<IconCrown className="text-[#8E4600] dark:text-white sm:ml-2" />
								</div>

								<div className="flex items-center justify-center gap-2">
									<h4 className="font-semibold text-xs sm:text-lg">Monthly</h4>
									<div
										className={`relative w-10 h-5 sm:w-16 sm:h-8 flex items-center rounded-full p-1 cursor-pointer transition-colors ${
											isToggled ? 'bg-green-500' : 'bg-gray-300'
										}`}
										onClick={() => {
											toggle();
											setText(text === 'Monthly' ? 'Yearly' : 'Monthly');
										}}
									>
										<div
											className={`bg-white w-4 h-4 sm:w-6 sm:h-6 rounded-full shadow-md transform transition-transform ${
												isToggled ? 'translate-x-4 sm:translate-x-8' : 'sm:translate-x-0'
											}`}
										/>
									</div>
									<h4 className="font-semibold text-xs sm:text-lg">Yearly</h4>
								</div>
							</div>

							<h5 className="w-full min-h-14 text-sm sm:text-base dark:text-[#BDBDBD]">
								The complete plan plan. Includes all the features to start track your life with your online journal.
							</h5>
							<div className="w-full flex items-center border-b border-lt-beige dark:border-[#6E6E40] pb-0 min-h-[150px]">
								{text === 'Monthly' ? (
									<>
										<div className="flex flex-col">
											<div className="flex items-end">
												<h3 className="text-[#8E4600] dark:text-white font-extrabold text-4xl">$3.99</h3>
												<h5 className="text-[#8E4600] dark:text-[#BDBDBD]"> / Month</h5>
											</div>
											<h6 className="text-sm text-[#8E4600] dark:text-[#BDBDBD]">(+VAT)</h6>
										</div>
									</>
								) : (
									<div className="flex flex-col">
										{' '}
										<div className="flex items-end">
											<div>
												<h3 className="text-[#8E4600] dark:text-white font-semibold text-xl line-through decoration-2">
													$47.9
												</h3>
												<h3 className="text-[#8E4600] dark:text-white font-extrabold text-4xl">$31.99</h3>
											</div>
											<h5 className="text-[#8E4600] dark:text-[#BDBDBD]"> / Yearly</h5>
										</div>
										<h6 className="dark:text-[#BDBDBD]">%33 Discount</h6>
										<h6 className="text-sm text-[#8E4600] dark:text-[#BDBDBD]">(+VAT)</h6>
									</div>
								)}
							</div>
							<ul className="w-full mt-5 flex flex-col pb-8 text-start">
								{proPlan.map((item, index) => (
									<li key={index} className="flex gap-2 min-h-[50px]">
										<div className="min-w-5">{item.icon}</div>

										<h5 className="text-sm xl:text-base dark:text-[#BDBDBD]">{item.desc}</h5>
									</li>
								))}
							</ul>
							<div className="w-full flex justify-center">
								<Link
									to={`${isAuthenticated ? (profile.subscription.subscriptionType === SubscriptionType.FREE && profile.subscription.isTrial === false ? '/dashboard/profile' : '/dashboard/my-tables') : '/auth/register'} `}
									className="w-10/12 rounded-3xl bg-lt-lightCream dark:bg-lt-dark-primary-bg border border-[#6E6E40] font-bold py-4 items-center justify-center flex"
								>
									Start Your Free Trial
								</Link>
							</div>
						</div>
					</div>
					<h6 className="w-11/12 mx-auto text-lt-dark-inputPlaceholder text-xs mt-4">
						You can try all features without entering any credit card information during your free trial period. After
						your free trial, if you want to continue using the app, you can choose between monthly or yearly
						subscription options.
					</h6>
				</div>
			</div>
		</Element>
	);
}
