import { configureStore, combineReducers, createAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import myTablesReducer from './table/myTablesSlice';
import authReducer from './auth/authSlice';
import { myTablesApi } from '../services/api/myTables';
import { authApi } from '../services/api/auth';
import emailReducer from './auth/emailSlice';
import { socialApi } from '../services/api/social';
import { profileApi } from '../services/api/profile';
import profileReducer from './profile/profileSlice';
import socialReducer from './social/socialSlice';
import achievementReducer from './achievements/achievementsSlice';
import { achievementsApi } from '../services/api/achievement';
import { myJournalApi } from '../services/api/myJournal';
import shopReducer from './shop/shopItemSlice';
import { shopItemsApi } from '../services/api/shop';
import { emailApi } from '../services/api/email';
import { paymentApi } from '../services/api/payment';
import paymentReducer from './payment/paymentSlice';
const rootPersistConfig = {
	key: 'root',
	storage,
	whitelist: ['auth', 'myTables', 'profile', 'social', 'achievements', 'shopItems']
};

const authPersistConfig = {
	key: 'auth',
	storage,
	whitelist: ['token']
};

const myTablesPersistConfig = {
	key: 'myTables',
	storage
};

const profilePersistConfig = {
	key: 'profile',
	storage
};

const socialPersistConfig = {
	key: 'social',
	storage
};

const achievementPersistConfig = {
	key: 'achievement',
	storage
};

const shopPersistConfig = {
	key: 'shop',
	storage
};

const paymentPersistConfig = {
	key: 'payment',
	storage
};

// Global reset action
export const resetAction = createAction('reset');

const appReducer = combineReducers({
	auth: persistReducer(authPersistConfig, authReducer),
	email: emailReducer,
	myTables: persistReducer(myTablesPersistConfig, myTablesReducer),
	profile: persistReducer(profilePersistConfig, profileReducer),
	social: persistReducer(socialPersistConfig, socialReducer),
	achievements: persistReducer(achievementPersistConfig, achievementReducer),
	shopItems: persistReducer(shopPersistConfig, shopReducer),
	payment: persistReducer(paymentPersistConfig, paymentReducer),
	[myTablesApi.reducerPath]: myTablesApi.reducer,
	[socialApi.reducerPath]: socialApi.reducer,
	[profileApi.reducerPath]: profileApi.reducer,
	[authApi.reducerPath]: authApi.reducer,
	[achievementsApi.reducerPath]: achievementsApi.reducer,
	[myJournalApi.reducerPath]: myJournalApi.reducer,
	[shopItemsApi.reducerPath]: shopItemsApi.reducer,
	[emailApi.reducerPath]: emailApi.reducer,
	[paymentApi.reducerPath]: paymentApi.reducer
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: { type: string }) => {
	if (action.type === resetAction.type) {
		state = undefined;
	}
	return appReducer(state, action);
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false
		}).concat(
			authApi.middleware,
			myTablesApi.middleware,
			socialApi.middleware,
			profileApi.middleware,
			achievementsApi.middleware,
			myJournalApi.middleware,
			shopItemsApi.middleware,
			emailApi.middleware,
			paymentApi.middleware
		)
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
