import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../states/store';
import { useBuyShopItemMutation, useLazyGetShopItemsQuery } from '../../../services/api/shop';
import { useEffect, useRef, useState } from 'react';
import { getShopItemsSuccess } from '../../../states/shop/shopItemSlice';
import { isCustomErrorResponse } from '../../../utils/errorUtils';
import toast from 'react-hot-toast';
import {
	IconCaretDown,
	IconChevronLeft,
	IconChevronRight,
	IconCoins,
	IconSearch,
	IconSticker,
	IconTrash
} from '@tabler/icons-react';
import { achievementEarnedSuccess, buyShopItemSuccess } from '../../../states/profile/profileSlice';
import { IAchievement } from '../../../types/achievement.types';
import AchievementToast from '../../../components/profile/achievements/AchievementToast';
import CustomModule from '../../../components/customModule/customModule';
import Button from '../../../components/button/button';
import './Shop.css';
import { Helmet } from 'react-helmet';
import { ISticker } from '../../../types/sticker.types';
import { useDebounce } from '../../../hooks/useDebounce';

export default function Shop() {
	const dispatch = useDispatch();
	const [getShopItemsRequest] = useLazyGetShopItemsQuery();
	const [getShopItemsIsLoading, setGetShopItemsIsLoading] = useState(false);
	const { profile } = useSelector((state: RootState) => state.profile);
	const { shopItems, loadedPages, pagination } = useSelector((state: RootState) => state.shopItems);
	const [currentPage, setCurrentPage] = useState(1);
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [buyShopItemRequest, { isLoading: buyShopItemIsLoading }] = useBuyShopItemMutation();
	const [shopItem, setShopItem] = useState<ISticker>();
	const [loadingImages, setLoadingImages] = useState<{ [key: string]: boolean }>({});
	const [searchQuery, setSearchQuery] = useState('');
	const [sortOptions, setSortOptions] = useState({ sortBy: 'title', order: 'asc' });
	const debouncedQuery = useDebounce(searchQuery, 500);
	const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
	const sortDropdownRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (profile._id) {
			// Only fetch if user is logged in
			const fetchShopItems = async () => {
				setGetShopItemsIsLoading(true);
				try {
					const response = await getShopItemsRequest({
						page: currentPage,
						limit: 10,
						query: debouncedQuery,
						sortBy: sortOptions.sortBy,
						order: sortOptions.order
					}).unwrap();
					if (response) {
						dispatch(
							getShopItemsSuccess({ items: response.data, pagination: response.pagination, query: debouncedQuery })
						);
						// Initialize loading state for each image
						const newLoadingState: { [key: string]: boolean } = {};
						response.data.forEach((item: ISticker) => {
							newLoadingState[item._id] = true;
						});
						setLoadingImages(newLoadingState);
					}
				} catch (error) {
					if (isCustomErrorResponse(error)) {
						toast.error(error.data.message);
					} else if (error instanceof Error) {
						toast.error(error.message);
					} else {
						toast.error('An unknown error occurred. Please try again.');
					}
				} finally {
					setGetShopItemsIsLoading(false);
				}
			};

			fetchShopItems();
		}
	}, [currentPage, dispatch, loadedPages, profile._id, debouncedQuery, sortOptions]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent | TouchEvent | PointerEvent | KeyboardEvent | FocusEvent | Event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setIsOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handlePageSelect = (page: number) => {
		setCurrentPage(page);
		setIsOpen(false);
	};

	const handleDropdownToggle = () => {
		setIsOpen((prev) => !prev);
	};

	const isItemClaimed = (item: ISticker): boolean => {
		return profile.stickers.includes(item._id);
	};

	const openBuyModal = (shopItem: ISticker) => {
		(document.getElementById('shop-ays-modal') as HTMLDialogElement).showModal();
		setShopItem(shopItem);
	};

	const handleImageLoad = (itemId: string) => {
		setLoadingImages((prev) => ({
			...prev,
			[itemId]: false
		}));
	};

	const clearInput = () => {
		setSearchQuery('');
		handleChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setSearchQuery(value);
		setCurrentPage(1);
	};

	const buyItem = async () => {
		try {
			if (!shopItem) return;
			const response = await buyShopItemRequest({ itemId: shopItem._id }).unwrap();
			if (response) {
				dispatch(buyShopItemSuccess(shopItem));
				if (response.achievements.length > 0) {
					dispatch(achievementEarnedSuccess(response.achievements));
					response.achievements.forEach((achievement: IAchievement) => {
						toast(
							<AchievementToast
								title={achievement.title}
								description={achievement.description}
								threshold={achievement.threshold}
							/>
						);
					});
				}
				toast.success(response.message);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		} finally {
			(document.getElementById('shop-ays-modal') as HTMLDialogElement).close();
		}
	};

	const getPaginatedItems = () => {
		return shopItems[currentPage] || [];
	};

	const handleSortDropdownToggle = () => {
		setIsSortDropdownOpen((prev) => !prev);
	};

	const handleSortOptionSelect = (sortBy: string, order: string) => {
		setSortOptions({ sortBy, order });
		setIsSortDropdownOpen(false);
	};

	useEffect(() => {
		const handleClickOutsideSortDropdown = (event: MouseEvent) => {
			if (sortDropdownRef.current && !sortDropdownRef.current.contains(event.target as Node)) {
				setIsSortDropdownOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutsideSortDropdown);
		return () => {
			document.removeEventListener('mousedown', handleClickOutsideSortDropdown);
		};
	}, []);

	return (
		<div className="w-full flex flex-1 flex-col">
			<Helmet>
				<title>Shop</title>
				<meta name="description" content="Shop for stickers to decorate your journal." />
			</Helmet>
			<div className="w-full flex justify-between bg-lt-cream dark:bg-lt-dark-secondary-bg p-4 dark:border-lt-dark-secondary-border z-10">
				<h1 className="text-4xl font-bold">Shop</h1>
				<div className="flex flex-row items-center justify-end gap-2">
					<div className=" w-1/2 sm:w-auto flex items-center px-2 h-11 bg-lt-lightCream dark:bg-lt-dark-popupBorder border border-lt-beige dark:border-lt-dark-primary-border rounded-default relative">
						<IconSearch />
						<input
							disabled={getShopItemsIsLoading}
							value={searchQuery}
							onChange={handleChange}
							className="input input-bordered bg-transparent border-none outline-none focus:outline-none w-full pl-2"
							placeholder="Search Table"
							type="text"
						/>
						{searchQuery && (
							<IconTrash
								onClick={clearInput}
								className={`text-red-500 cursor-pointer absolute right-2 ${getShopItemsIsLoading ? 'cursor-not-allowed pointer-events-none opacity-50' : ''}`}
							/>
						)}
					</div>
					<div className="flex flex-row gap-2 mr-2 sm:mr-4">
						<div ref={sortDropdownRef} className="relative">
							<button
								className="flex items-center justify-center font-semibold px-2 py-0 bg-transparent shadow-none rounded-md border border-lt-beige dark:border-lt-dark-popupBorder h-[44px]"
								onClick={handleSortDropdownToggle}
							>
								Sort
								<IconCaretDown className="w-4 h-4" />
							</button>
							{isSortDropdownOpen && (
								<ul className="sort-dropdown rounded-md bg-lt-lightCream dark:bg-lt-dark-popupBackground w-28 p-0 shadow absolute top-full left-1/2 -translate-x-1/2 max-h-60 overflow-y-auto border-2 border-lt-beige dark:border-lt-dark-primary-border">
									<li>
										<button
											className="h-14 rounded-sm border-x-0 border-t-0 w-full border-b-2 border-lt-beige dark:border-lt-dark-primary-border"
											onClick={() => handleSortOptionSelect('title', 'asc')}
										>
											Name (A-Z)
										</button>
									</li>
									<li>
										<button
											className="h-14 rounded-sm border-x-0 border-t-0 w-full border-b-2 border-lt-beige dark:border-lt-dark-primary-border"
											onClick={() => handleSortOptionSelect('title', 'desc')}
										>
											Name (Z-A)
										</button>
									</li>
									<li>
										<button
											className="h-14 rounded-sm border-x-0 border-t-0 w-full border-b-2 border-lt-beige dark:border-lt-dark-primary-border"
											onClick={() => handleSortOptionSelect('cost', 'asc')}
										>
											Price (Low to High)
										</button>
									</li>
									<li>
										<button
											className="h-14 rounded-sm border-x-0 border-t-0 w-full z-[50]"
											onClick={() => handleSortOptionSelect('cost', 'desc')}
										>
											Price (High to Low)
										</button>
									</li>
								</ul>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="">
				{getShopItemsIsLoading ? (
					<div className="w-full flex justify-center h-screen">
						<span className="loading loading-spinner loading-lg text-center"></span>
					</div>
				) : (
					<div className="h-[calc(100dvh-257px)] md:h-[calc(100vh-168px)] overflow-y-auto px-10">
						<div className="">
							<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 py-5">
								{getPaginatedItems().map((item, index) => (
									<div
										key={index}
										className="w-full h-auto flex flex-col justify-between gap-4 border-2 border-lt-beige dark:border-lt-dark-secondary-border p-2 rounded min-h-[250px]"
									>
										<CustomModule
											id="shop-ays-modal"
											title="Are you sure you want to buy this item?"
											description={`Your updated balance will be ${shopItem?.cost && profile.appMoney - shopItem.cost} coins. Do you want to proceed?`}
											buttonText="Buy"
											onButtonClick={() => {
												buyItem();
											}}
											isLoading={buyShopItemIsLoading}
										/>
										<div className="flex flex-row items-center gap-2">
											<IconSticker />
											<h2 className="font-semibold">{item.title}</h2>
										</div>
										<div className="flex w-full h-full items-center justify-center">
											<div className="sticker-background w-36 h-44 flex items-center justify-center">
												{loadingImages[item._id] && <span className="loading loading-spinner loading-md"></span>}
												<img
													src={item.stickerUrl}
													className={`max-w-[135px] max-h-[135px] rounded object-cover ${loadingImages[item._id] ? 'hidden' : ''}`}
													alt="Item Photo"
													onLoad={() => handleImageLoad(item._id)}
												/>
												<div className="sticker-corner top-right bg-lt-cream dark:bg-lt-dark-secondary-bg "></div>
												<div className="sticker-corner bottom-left"></div>
											</div>
										</div>
										<div className="flex flex-row items-center justify-between">
											<div className="flex flex-row ext-lt-dark-primary-disabled-text dark:text-lt-white">
												<IconCoins />
												<p className="ext-lt-dark-primary-disabled-text dark:text-lt-white">{item.cost}</p>
											</div>
											<Button
												disabled={isItemClaimed(item) || profile.appMoney < item.cost}
												type="button"
												variant="btn-primary-light"
												className="btn-sm text-sm border-none rounded-md"
												onClick={() => openBuyModal(item)}
											>
												{isItemClaimed(item) ? 'Claimed' : 'Buy'}
											</Button>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				)}
				<div className="sticky right-0 left-0 bottom-0 z-20 bg-lt-cream dark:bg-lt-dark-secondary-bg flex justify-between border-t-2 border-lt-beige dark:border-lt-dark-popupBorder">
					<button
						disabled={getShopItemsIsLoading || currentPage === 1}
						onClick={() => {
							if (currentPage === 1) return;
							setCurrentPage(currentPage - 1);
						}}
						className="btn bg-transparent border-y-0 border-l-0 rounded-none border-r-2 border-lt-beige dark:border-lt-dark-popupBorder shadow-none h-[58px]"
					>
						<IconChevronLeft />
					</button>
					<div ref={dropdownRef} className="relative mx-4">
						<button
							disabled={getShopItemsIsLoading}
							className="btn bg-transparent shadow-none border-y-0 rounded-none border-x-2 border-lt-beige dark:border-lt-dark-popupBorder h-[58px]"
							onClick={handleDropdownToggle}
						>
							{currentPage} of {pagination.totalPages}
							<IconCaretDown className="w-4 h-4" />
						</button>
						{isOpen && (
							<ul className=" rounded-md z-[1] bg-lt-lightCream dark:bg-lt-dark-popupBackground w-28 p-0 shadow absolute bottom-full left-1/2 -translate-x-1/2 max-h-60 overflow-y-auto border-2 border-lt-beige dark:border-lt-dark-primary-border">
								{Array(pagination.totalPages)
									.fill(null)
									.map((_, i, arr) => (
										<li key={i}>
											<button
												className={`h-10 rounded-sm border-x-0 border-t-0 w-full ${i !== arr.length - 1 ? 'border-b-2 border-lt-beige dark:border-lt-dark-primary-border' : ''}`}
												onClick={() => handlePageSelect(i + 1)}
												value={i + 1}
											>
												{i + 1}
											</button>
										</li>
									))}
							</ul>
						)}
					</div>

					<button
						disabled={getShopItemsIsLoading || currentPage === pagination.totalPages}
						onClick={() => {
							if (currentPage === pagination.totalPages) return;
							setCurrentPage(currentPage + 1);
						}}
						className="btn bg-transparent border-y-0 border-r-0 rounded-none border-l-2 border-lt-beige dark:border-lt-dark-popupBorder shadow-none h-[58px]"
					>
						<IconChevronRight />
					</button>
				</div>
			</div>
		</div>
	);
}
