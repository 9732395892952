import { IconSticker } from '@tabler/icons-react';
import { useLazyGetFriendsStickersQuery, useLazyGetMyStickersQuery } from '../../../services/api/profile';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../states/store';
import { getFriendsStickersSuccess, getMyStickersSuccess } from '../../../states/profile/profileSlice';
import { isCustomErrorResponse } from '../../../utils/errorUtils';
import toast from 'react-hot-toast';
import { ISticker } from '../../../types/sticker.types';
import { useParams } from 'react-router-dom';

export default function Stickers({ isFollowing }: { isFollowing?: boolean }) {
	const dispatch = useDispatch();
	const { userId } = useParams<string>();
	const [getMyStickersRequest] = useLazyGetMyStickersQuery();
	const [getFriendsStickersRequest] = useLazyGetFriendsStickersQuery();
	const [friendsStickers, setFriendsStickers] = useState<ISticker[]>();
	const myStickers = useSelector((state: RootState) => state.profile.stickers);
	const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

	useEffect(() => {
		const fetchMyStickers = async () => {
			if (myStickers && myStickers.length > 0) {
				return;
			}
			try {
				const response = await getMyStickersRequest().unwrap();
				if (response.data) {
					dispatch(getMyStickersSuccess(response.data));
				}
			} catch (error) {
				if (isCustomErrorResponse(error)) {
					toast.error(error.data.message);
				} else if (error instanceof Error) {
					toast.error(error.message);
				} else {
					toast.error('An unknown error occurred. Please refresh the page.');
				}
			}
		};

		if (!isAuthenticated) return;
		if (!isFollowing) {
			fetchMyStickers();
		}
	}, [myStickers, getMyStickersRequest, dispatch, isFollowing, isAuthenticated]);

	useEffect(() => {
		const getFriendsStickers = async () => {
			try {
				if (!userId) return;
				const response = await getFriendsStickersRequest(userId).unwrap();
				if (response) {
					setFriendsStickers(response.data);
					dispatch(getFriendsStickersSuccess(response.data));
				}
			} catch (error) {
				if (isCustomErrorResponse(error)) {
					toast.error(error.data.message);
				} else if (error instanceof Error) {
					toast.error(error.message);
				} else {
					toast.error('An unknown error occurred. Please refresh the page.');
				}
			}
		};
		if (!isAuthenticated) return;
		if (isFollowing) {
			getFriendsStickers();
		}
	}, [isFollowing, isAuthenticated, userId]);

	const closeStickersModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		(document.getElementById('stickers_modal') as HTMLDialogElement)?.close();
	};

	return (
		<div className="flex gap-1 items-end">
			<dialog id="stickers_modal" className="modal">
				<div className="p-0 modal-box max-w-4xl max-h-[600px] bg-lt-light-popupBackground dark:bg-lt-dark-popupBackground border border-lt-light-popupBorder dark:border-lt-dark-popupBorder">
					<div className="sticky top-0 bg-lt-light-popupBackground dark:bg-lt-dark-popupBackground z-10 p-4">
						<form method="dialog">
							<button
								onClick={(e) => closeStickersModal(e)}
								className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
							>
								✕
							</button>
						</form>
						<h3 className="font-bold text-3xl">{!isFollowing && !userId ? 'My' : 'Friends'} Stickers</h3>
					</div>
					<div className="p-4">
						{!isFollowing && !userId ? (
							<>
								<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
									{myStickers.map((sticker: ISticker, index: number) => (
										<div
											className="flex flex-col items-center justify-center bg-lt-cream dark:bg-lt-dark-secondary-bg dark:border-lt-dark-popupBorder border border-[#cebe20] w-[160px] h-[195px]"
											key={index}
										>
											<div className="bg-lt-lightCream dark:bg-lt-dark-popupBackground border border-[#cebe20] dark:border-lt-dark-popupBorder h-32 w-32 mt-4 flex justify-center items-center">
												<img className="w-12 h-12 sm:w-28 sm:h-28 " src={sticker.stickerUrl} alt={sticker.title} />
											</div>
											<h6 className="mt-4">{sticker.title}</h6>
										</div>
									))}
								</div>
							</>
						) : (
							<>
								<div className="grid grid-cols-2 sm:grid-cols-3  md:grid-cols-4 gap-4">
									{friendsStickers?.map((sticker: ISticker, index: number) => (
										<div
											className="flex flex-col items-center justify-center bg-lt-cream border border-[#cebe20] dark:bg-lt-dark-popupBackground dark:border-lt-dark-popupBorder w-[160px] h-[195px]"
											key={index}
										>
											<div className="bg-lt-lightCream border border-[#cebe20] h-32 w-32 mt-4 flex justify-center items-center">
												<img className="w-12 h-12 sm:w-28 sm:h-28 " src={sticker.stickerUrl} alt={sticker.title} />
											</div>
											<h6 className="mt-4">{sticker.title}</h6>
										</div>
									))}
								</div>
							</>
						)}
					</div>
				</div>
			</dialog>
			<IconSticker />
			<span className="flex gap-1">
				<strong>{isFollowing ? (friendsStickers?.length ?? 0) : myStickers.length}</strong>
				<button
					disabled={(!isFollowing && !myStickers?.length) || (isFollowing && !friendsStickers?.length)}
					onClick={() => (document.getElementById('stickers_modal') as HTMLDialogElement)?.showModal()}
					className={`text-lt-beige dark:text-lt-dark-popupText font-semibold text-xs sm:text-base ${(!isFollowing && !myStickers?.length) || (isFollowing && !friendsStickers?.length) ? '' : 'dark:hover:text-lt-beige transition-colors duration-300'}`}
				>
					Stickers
				</button>
			</span>
		</div>
	);
}
