import { useNavigate } from 'react-router-dom';

export default function PaymentSuccess() {
	const navigate = useNavigate();

	return (
		<div className="min-h-screen flex items-center justify-center bg-lt-lightCream dark:bg-lt-dark-primary-bg">
			<div className="bg-lt-cream dark:bg-lt-dark-secondary-bg p-8 rounded-lg shadow-lg max-w-md w-full text-center border-2 border-lt-beige dark:border-lt-dark-popupBorder">
				<div className="w-20 h-20 bg-green-100 dark:bg-green-900 rounded-full flex items-center justify-center mx-auto mb-6">
					<svg
						className="w-10 h-10 text-green-500"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
					</svg>
				</div>

				<h2 className="text-2xl font-bold mb-4 text-lt-dark dark:text-lt-dark-primary-text">Payment Successful!</h2>

				<p className="text-lt-dark-inputPlaceholder dark:text-lt-dark-secondary-text mb-6">
					Thank you for your purchase. Your account has been successfully upgraded. You now have access to all premium
					features.
				</p>

				<div className="space-y-4">
					<button
						onClick={() => navigate('/dashboard/profile')}
						className="w-full bg-lt-light-primary-bg hover:bg-lt-light-primary-hover-bg text-lt-white dark:bg-lt-dark-primary-bg dark:hover:bg-lt-dark-primary-hover-bg dark:text-lt-dark-primary-text py-2 px-4 rounded-lg transition-colors"
					>
						Go to Dashboard
					</button>
				</div>
			</div>
		</div>
	);
}
