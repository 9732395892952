import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Logo from '/src/assets/logo.svg';

export default function TermsAndConditionsTr() {
	const pathname = window.location.pathname;
	const parts = pathname.split('/');
	const language = parts[1];

	return (
		<div>
			<Helmet>
				<title>Life on Track - Terms of Service</title>
				<meta name="description" content="Terms and conditions of Life on Track." />
			</Helmet>
			<header className="sticky top-0 z-50 w-full h-[70px] px-4 md:px-12 flex items-center justify-between border-b  bg-lt-lightCream dark:bg-lt-dark-secondary-bg">
				<div className="flex justify-start items-center md:gap-2 w-4/12 md:w-1/4">
					<img src={Logo} alt="logo" className="w-8 h-8 md:w-10 md:h-10" />
					<Link to="/" className="text-lt-beige dark:text-white font-extrabold text-sm sm:text-2xl">
						Life on Track
					</Link>
				</div>
				<div className="hidden md:flex justify-between base:justify-center base:gap-10 md:w-1/2">
					<Link
						to="/en/privacy-policy"
						className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
					>
						Privacy Policy
					</Link>
					<Link
						to="/en/refund-policy"
						className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
					>
						Refund Policy
					</Link>
					<Link
						to="/"
						className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
					>
						Home
					</Link>
				</div>
				<div className="navbar w-1/3 flex justify-center md:hidden">
					<div className="navbar-center md:hidden">
						<ul className="menu menu-md menu-horizontal px-1">
							<li>
								<details>
									<summary className="text-lt-beige dark:text-[#5D4037] active:bg-lt-lightCream active:text-lt-beige dark:active:bg-transparent">
										Menu
									</summary>
									<ul className="flex flex-col text-xs sm:text-base font-semibold justify-between text-black dark:text-white dark:bg-lt-dark-popupBackground  divide-y divide-lt-beige dark:divide-[#6E6E40] bg-lt-lightCream px-0 ">
										<li className="px-4">
											<Link
												to="/en/privacy-policy"
												className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
											>
												Privacy Policy
											</Link>
										</li>
										<li className="px-4">
											<Link
												to="/en/refund-policy"
												className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
											>
												Refund Policy
											</Link>
										</li>
										<li className="px-4">
											<Link
												to="/"
												className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
											>
												Home
											</Link>
										</li>
									</ul>
								</details>
							</li>
						</ul>
					</div>
				</div>
				<div className="flex justify-end gap-1 sm:gap-4 text-xs sm:text-base md:text-xl w-2/12 md:w-1/4">
					<Link
						className={`${language === 'en' ? 'text-lt-beige dark:text-[#5D4037]' : 'text-black dark:text-white'} hover:text-lt-beige}`}
						to="/en/terms-of-service"
					>
						EN
					</Link>
					/
					<Link
						className={`${language === 'tr' ? 'text-lt-beige dark:text-[#5D4037]' : 'text-black dark:text-white'} hover:text-lt-beige}`}
						to="/tr/terms-of-service"
					>
						TR
					</Link>
				</div>
			</header>
			<div className="p-6 max-w-4xl mx-auto mt-5 md:mt-10  h-screen overflow-y-auto pb-40">
				<div className="max-w-3xl p-6">
					<h2 className="text-2xl font-bold mb-4 text-center">KULLANICI SÖZLEŞMESİ</h2>
					<h2 className="text-2xl font-bold mb-4 text-center">HÜKÜM VE KOŞULLAR</h2>
					<h3 className="text-xl font-semibold mt-6 mb-3">1. TARAFALAR, KONU VE KAPSAM</h3>
					<p className="text-gray-700 dark:text-white mb-4">
						İşbu kullanıcı sözleşmesi (“<strong>Sözleşme</strong>”), “Yağcılar Mahallesi Saadet Sokağı No : 18/-
						Adapazarı/ Sakarya” adresinde bulunan Kaan Mert (“<strong>Kaan Software Studio</strong>
						”) ile lifeontrack.co İnternet Sitesi’ni (“<strong>Life on Track</strong>”) ziyaret ederek Sözleşme’de
						belirtilen koşullar dahilinde yararlanan kişiler (“<strong>Kullanıcı”</strong>) arasında kurulmuştur. İşbu
						Sözleşme’nin konusu Life on Track’ın kullanım koşulları ve Kullanıcı ile Kaan Software Studio (“
						<strong>Taraflar</strong>) nın hak ve yükümlülüklerinin düzenlenmesidir.
					</p>
					<p className="text-gray-700 dark:text-white mb-4">
						Kullanıcı, Life on Track internet sitesini ziyaret etmekle kullanıma, üyeliğe ve hizmetlere ilişkin Kaan
						Software Studio tarafından açıklanan her türlü beyanı, işbu Sözleşme hüküm ve koşullarını kabul etmiş
						sayılır ve bahsi geçen beyanlarda belirtilen her türlü hususa uygun olarak davranacağını kabul, beyan ve
						taahhüt eder.
					</p>
					<h3 className="text-xl font-semibold mt-6 mb-3">2. TANIMLAR</h3>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>2.1</strong> İşbu Sözleşme’de; <br />
						<strong>2.1.1 Ödeme İşlemcisi:</strong> Kaan Software Studio tarafından kullanıcıya fatura kesmek için
						kullanılan üçüncü-şahıs ödeme işlemcilerini, <br />
						<strong>2.1.2 Sisteme Erişim Araçları:</strong> Kullanıcı’nın Life on Track’a erişimini sağlayan kullanıcı
						adı, şifre gibi yalnızca Kullanıcı’nın bilgisinde olan bilgileri, <br />
						<strong>2.1.3 Ücretsiz Üyelik:</strong> Life on Track'e herhangi bir ücret ödemeksizin sınırlı olarak erişim
						sağlayan üyelik türünü ifade eder, <br />
						<strong>2.1.4 Üyelik:</strong> Life on Track içerisinde Aylık, Yıllık ve Ömür Boyu Üyelik yöntemleri ile
						sunulan ücretli hizmeti, <br />
					</p>
					<h3 className="text-xl font-semibold mt-6 mb-3">3. TARAFLARIN HAK VE YÜKÜMLÜLÜKLERİ</h3>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>3.1</strong> Kullanıcı, Life on Track'teki ürün veya hizmetlerden faydalanırken ve herhangi bir
						işlemi yerine getirirken işbu Sözleşme içerisinde yer alan tüm şartlara, belirtilen kurallara ve
						yürürlükteki tüm mevzuata uygun hareket edeceğini, işbu sözleşmede belirtilen tüm şart ve kuralları
						okuduğunu, anladığını ve onayladığını kabul eder.
					</p>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>3.2</strong> Kullanıcı, Kaan Software Studio’nun Sözleşme konusu ürün ve hizmetleri ifasına yönelik
						olarak ve/veya bu amaçla sözleşme akdettiği iş ortaklarına, Kullanıcı’ya ait ticari bilgileri açıklamaya
						yetkili olacağını, bu hususu kabul ettiğini beyan eder.
					</p>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>3.3</strong> Kullanıcı, Life on Track’te yer alan tablolarda yer verdiği ve paylaşmayı seçtiği tüm
						kişisel verileri tamamen kendi iradesiyle paylaştığını kabul ve beyan eder.
					</p>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>3.4</strong> Kullanıcı, Aydınlatma Metni ve Gizlilik Politikası kapsamında her zaman Kaan Software
						Studio’dan kendisi ile ilgili olarak tutulan verilerin silinmesini talep edebilir.
					</p>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>3.5</strong> Kullanıcı, Life on Track’te yer alan Aylık, Yıllık ve Ömür Boyu Üyelik seçenekleri ile
						Life on Track’ın sunduğu tüm hizmetlere erişim satın alabilir.
					</p>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>3.6</strong> Kaan Software Studio, Life on Track aracılığıyla Kullanıcı’ya günlük hayatına ilişkin
						kaydetmek istediği tablo ve görseller oluşturma ve kullanmayı sağlama hizmetini (“<strong>Hizmetler</strong>
						”) sunmaktadır.
					</p>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>3.7</strong> Kaan Software Studio, uygun gördüğünde ve hiçbir bildirimde bulunmadan ve önel
						vermeden, Life on Track’ta sunulan bilgileri, ürün ve hizmetleri ve içerikleri, kullanma koşullarını her
						zaman tek taraflı olarak değiştirebilme, yeniden organize etme hakkını saklı tutar.
					</p>
					<h3 className="text-xl font-semibold mt-6 mb-3">4. ÜCRETLENDİRME VE ÜYELİK TÜRLERİ</h3>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>4.1</strong> Yeni kayıt olan her kullanıcı Life on Track'i 7 gün boyunca ücretsiz deneme sürümü ile
						kullanabilir. Deneme süresi sonunda üyelik satın alınmaması durumunda kullanıcı yeni tablo oluşturamaz ve
						mevcut tablolarına erişemez. Üyelik satın alınması halinde tüm vergiler dâhil satış fiyatı için
						Kullanıcı'dan ayrıca onay alınacaktır. Üyelik satın alan kullanıcılar sınırsız tablo oluşturabilir, günlük
						giriş yaparak coin kazanabilir ve Life on Track'te sunulan tüm özelliklerden yararlanma hakkına sahip olur.
					</p>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>4.2</strong> Kullanıcı, sunulan hizmetin “Elektronik ortamda teslim edilebilir ürün” kategorisinde
						olması sebebiyle iade yapılamayacağını kabul eder.
					</p>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>4.3</strong> Kaan Software Studio, Life on Track’taki satın almalarda Kullanıcılara fatura kesmek
						için bir üçüncü-şahıs ödeme işlemcisi (“<strong>Ödeme İşlemcisi</strong>”) kullanabilir. Ödeme süreci,
						Sözleşme’ye ek olarak Ödeme İşlemcisi’nin hüküm, koşul ve gizlilik şartlarına tabi olacaktır. Kaan Software
						Studio, Ödeme İşlemcisi sebebiyle meydana gelecek herhangi bir zarar ve/veya sonuçtan sorumlu değildir.
					</p>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>4.4</strong> Ödemelere ilişkin şartlar, Kullanıcıların seçmiş olduğu ödeme yöntemi veya kredi kartı
						sağlayıcıları veya finansal kuruluşlarıyla aralarındaki sözleşme hükümlerine bağlı olacaktır.
					</p>
					<h3 className="text-xl font-semibold mt-6 mb-3">5. CAYMA HAKKI VE FESİH</h3>
					<p className="text-gray-700 dark:text-white mb-4">
						Kullanıcı, kendisine sunulan hizmetin Mesafeli Sözleşmeler Yönetmeliği’nin “Cayma Hakkının İstisnaları”
						başlıklı 15. maddesinin 1. fıkrasının (h) bendinde belirtilen “cayma hakkı süresi sona ermeden önce,
						tüketicinin onayı ile ifasına başlanan hizmetlere ilişkin sözleşmeler” ve maddenin 1. fıkrasının (ğ)
						bendinde belirtilen “elektronik ortamda anında ifa edilen hizmetler ile tüketiciye anında teslim edilen
						gayri maddi mallara ilişkin sözleşmeler” kapsamında olması nedeniyle cayma hakkına sahip değildir. Ancak
						Kullanıcı,Sözleşme’yi herhangi bir gerekçe göstermeksizin ve cezai şart ödemeksizin istediği zaman feshetme
						hakkına sahiptir. Fesih halinde ödenmiş olan tutar Kullanıcı’ya iade edilmez, Kullanıcı üyelik süresinin
						sonuna kadar hizmetten faydalanmaya devam edebilir.
					</p>
					<h3 className="text-xl font-semibold mt-6 mb-3">6. YETKİLİ MAHKEME</h3>
					<p className="text-gray-700 dark:text-white mb-4">
						İşbu sözleşmenin yorumlanmasına veya ifasına ilişkin uyuşmazlıkların çözülmesinde Türk hukuku
						uygulanacaktır. İşbu sözleşmeden doğan uyuşmazlıkların çözümünde, Sanayi ve Ticaret Bakanlığınca ilan edilen
						değere kadar Tüketici Hakem Heyetleri ile Alıcı’nın veya Satıcı’nın yerleşim yerindeki Tüketici Mahkemeleri
						yetkilidir.
					</p>
					<h3 className="text-xl font-semibold mt-6 mb-3">7. YÜRÜRLÜK VE DİĞER HÜKÜMLER</h3>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>7.1</strong> Kullanıcı, işbu Sözleşme ’de yer alan maddelerin tümünü okuduğunu, anladığını, kabul
						ettiğini ve kendisiyle ilgili olarak verdiği bilgilerin doğruluğunu onayladığını beyan, kabul ve taahhüt
						eder.
					</p>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>7.2</strong> İşbu Sözleşme, Kullanıcı’nın üyelik talebine elektronik olarak onay verilmesi ile
						karşılıklı olarak kabul edilerek yürürlüğe girmiştir.
					</p>
					<p className="text-gray-700 dark:text-white mb-4">
						<strong>7.3</strong> İşbu Sözleşme 7 (yedi) maddeden oluşmakta olup, Kullanıcı’nın Life on Track’ta yer alan
						Sözleşme’yi onaylaması neticesinde, tarafların serbest iradeleri ile kurulmuştur.
					</p>
				</div>
			</div>
		</div>
	);
}
