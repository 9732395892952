import { createApi } from '@reduxjs/toolkit/query/react';
import { ISticker } from '../../types/sticker.types';
import { baseQueryWithReauth } from './customBaseQuery';
import { IAchievement } from '../../types/achievement.types';

interface ShopItemsResponse {
	data: ISticker[];
	pagination: {
		page: number;
		totalPages: number;
		totalShopItems: number;
	};
	sort: {
		sortBy: string;
		order: string;
	};
	search: {
		query: string;
	};
}

export interface BuyShopItemRequest {
	itemId: string;
}

export interface BuyShopItemResponse {
	message: string;
	achievements: IAchievement[];
}

export const shopItemsApi = createApi({
	reducerPath: 'shopItemsApi',
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getShopItems: builder.query<
			ShopItemsResponse,
			{ page?: number; limit?: number; query?: string; sortBy?: string; order?: string }
		>({
			query: ({ page, limit, query, sortBy, order }) =>
				`api/shop/get-items?q=${query}&page=${page}&limit=${limit}&sortBy=${sortBy}&order=${order}`
		}),
		buyShopItem: builder.mutation<BuyShopItemResponse, BuyShopItemRequest>({
			query: (body) => ({
				url: `api/shop/buy-item`,
				method: 'POST',
				body
			})
		})
	})
});

export const { useLazyGetShopItemsQuery, useBuyShopItemMutation } = shopItemsApi;
